import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'

import 'src/common/styles/hideable-select.scss'


// A select input that may be shown/hidden by clicking on text positioned above it.
const HideableSelect = ({ name, options, isMulti, onSelect, footerValue }) => {
  const [selectExpanded, setSelectExpanded] = useState(false);

  const onControlClick = clickEvent => {
    setSelectExpanded(!selectExpanded);
    clickEvent.stopPropagation();
  }

  return (
    <div className="hideable-select">
      <div className="hideable-select-control" onClick={onControlClick}>{selectExpanded ? 'Hide' : 'Show'} {name}</div>
      <div className={selectExpanded ? "hideable-select-input show" : "hideable-select-input hide"} onClick={e => e.stopPropagation()}>
        <Select
          options={options}
          onChange={onSelect}
          isMulti={isMulti}
          isClearable={true} />
        {footerValue ? <div className="hideable-select-footer">{footerValue}</div> : null}
      </div>
    </div>
  )
};

HideableSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  isMulti: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  footerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default HideableSelect;
