// Based on a 5 day work week
export const calculateWeeklyTotal = (value, period) => period === 'DAY' ? value*5 : value;

export const numberToOrdinal = n => {
  const j = n % 10;
  const k = n % 100;
  return j === 1 && k !== 11 ? n + 'st'
    : j === 2 && k !== 12 ? n + 'nd'
    : j === 3 && k !== 13 ? n + 'rd'
    : n + 'th';
};

// Given an array of objects that have an id property, return the next ID that should
// be used. This assumes the IDs of objects in the array are in ascending order.
export const nextID = arr => arr.length > 0 ? arr[arr.length-1].id + 1 : 1;
