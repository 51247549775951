import React from 'react';
import PropTypes from 'prop-types';

import { INCIDENT_TYPE_SETTINGS, INCIDENT_TYPE_COLORS } from 'src/guard/constants';
import { incidentSettingEnabled } from 'src/guard/settingsHelpers';
import Setting from 'src/guard/Setting';


// This can be used for incident types that are mapped to a single setting
const SwitchSetting = ({ icon, title, settings, type, updateSetting, dataTipId, dataTip }) => (
  <Setting
    dataTipId={dataTipId}
    dataTip={dataTip}
    icon={icon}
    color={INCIDENT_TYPE_COLORS[type]}
    title={title}
    defaultValue={incidentSettingEnabled(settings, type)}
    onChange={checked => updateSetting(INCIDENT_TYPE_SETTINGS[type][0], checked)}
  />
);

SwitchSetting.propTypes = {
  dataTipId: PropTypes.string.isRequired,
  dataTip: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  updateSetting: PropTypes.func.isRequired
};

export default SwitchSetting;
