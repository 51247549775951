import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import "src/dashboard/styles/save-bar.scss";


const SaveBar = ({ changeCount, onSave, onCancel }) => {
  const [ isSaving, setIsSaving ] = useState(false);
  useEffect(() => {
    setIsSaving(false);
  }, [changeCount, setIsSaving]);

  const onSaveClick = () => {
    setIsSaving(true);
    onSave();
  };

  return (
    <div>
      <div className={classnames('save-bar footer', changeCount > 0 ? 'show-bar' : "")}>
        <button className="btn save" onClick={onSaveClick} disabled={isSaving}>Save</button>
        <button className="btn cancel" onClick={onCancel} disabled={isSaving}>Cancel</button>
        {<span>{changeCount} unsaved change{changeCount > 1 ? 's' : ''}</span>}
      </div>
    </div>
  );
};

SaveBar.propTypes = {
  changeCount: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default SaveBar;
