import React, { useState } from 'react';

import AdminPage from 'src/common/AdminPage';
import PortfolioDropdown from 'src/common/PortfolioDropdown';
import PortfolioPages from 'src/dashboard/PortfolioPages';
import SaveBar from 'src/dashboard/SaveBar';
import FundraiserSettingsForm from 'src/fundraiser/setting/FundraiserSettingsForm';


const Dashboard = () => {
  const [ settingUpdatesCount, setSettingUpdatesCount ] = useState(0);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isCancelling, setIsCancelling ] = useState(false);

  return (
    <AdminPage>
      <div className="dashboard">
        <div className="row">
          <div className="mb-3 col-12">
            <PortfolioDropdown />
          </div>
        </div>
        <PortfolioPages />
        <FundraiserSettingsForm
          onFormUpdate={numUpdates => setSettingUpdatesCount(numUpdates)}
          isSaving={isSaving}
          saveComplete={() => setIsSaving(false)}
          isCancelling={isCancelling}
          cancelComplete={() => setIsCancelling(false)}
        />
        <SaveBar
          changeCount={settingUpdatesCount}
          onSave={() => setIsSaving(true)}
          onCancel={() => setIsCancelling(true)}
        />
      </div>
    </AdminPage>
  );
};

export default Dashboard;
