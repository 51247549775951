import React from 'react';

import CustomDropdown from 'src/common/CustomDropdown';
import Setting from 'src/fundraiser/setting/Setting';

import 'src/fundraiser/setting/styles/dropdown-setting.scss';


const DropdownSetting = ({ id, title, description, settingValue, settingOptions, hasUnsavedValue, onSelect }) => {
  const options = settingOptions.map(setting => {
    return {
      value: setting.key,
      label: setting.value,
    }
  });

  const handleChange = ({ value }) => onSelect(value);

  return (
    <div className="dropdown-setting">
      <Setting id={id} title={title} description={description} hasUnsavedValue={hasUnsavedValue}>
        <CustomDropdown
          options={options}
          onChange={handleChange}
          isSearchable={false}
          name={title}
          value={{label: settingOptions.find(p => p.key === settingValue).value, value: settingValue}}
        />
      </Setting>
    </div>
  );
}

export default DropdownSetting;
