export const getDefaultReportColors = numColors => {
  // 32 default chart colors stolen from google
  const defaultReportColors = [
    '#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477',
    '#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300',
    '#8B0707','#651067','#329262','#5574A6','#3B3EAC','#B77322','#16D620','#B91383',
    '#F4539E','#9C5935','#A9C413','#2A778D','#668D1C','#BEA413','#0C5922','#743711'
  ];
  // Repeat the colors when numColors > defaultReportColors.length
  return new Array(numColors).fill(defaultReportColors).flat();
};
