import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons'

import Loading from 'src/common/Loading';
import ReportInputs from 'src/report_dashboard/ReportInputs';


// ReportInputs and buttons to run or download a report
const ReportControls = ({ reportInputs, onChange, onRunReport, onDownloadReport, isDownloading, hideDownloadButton }) => {
  const [reportInputsByID, setReportInputsByID] = useState({});
  const [reportInputValuesByID, setReportInputValuesByID] = useState({});

  // Reset input values when input fields change
  useEffect(() => {
    let newInputs = {};
    let newInputValues = {};
    for(const reportInput of reportInputs) {
      newInputs[reportInput.id] = reportInput;
      newInputValues[reportInput.id] = '';
    }
    setReportInputsByID(newInputs);
    setReportInputValuesByID(newInputValues);
  }, [reportInputs])

  const onInputChange = (id, value) => {
    setReportInputValuesByID({
      ...reportInputValuesByID,
      [id]: value
    });
    onChange();
  };

  // Enable buttons when all inputs have been populated
  const [enableButtons, setEnableButtons] = useState(false);
  useEffect(() => {
    let allSet = true;
    for(const reportInput of reportInputs) {
      // The multi-select input may set the input value to an empty array or null when it is cleared
      if(
        !reportInput.isOptional
        && (
          reportInputValuesByID[reportInput.id] === null
          || reportInputValuesByID[reportInput.id] === ''
          || (Array.isArray(reportInputValuesByID[reportInput.id]) && reportInputValuesByID[reportInput.id].length === 0)
        )
      ) {
        allSet = false;
        break;
      }
    }
    setEnableButtons(allSet);
  }, [reportInputValuesByID, reportInputsByID, reportInputs]);

  const onRunClick = () => {
    onRunReport(reportInputValuesByID);
  };

  const onDownloadClick = () => {
    onDownloadReport(reportInputValuesByID);
  };

  return (
    <div className="report-controls row pb-2">
      {reportInputs.length ?
        <ReportInputs reportInputs={reportInputs} reportInputValuesByID={reportInputValuesByID} onInputChange={onInputChange} />
        : null
      }
      <div className="report-control-buttons col">
        <button disabled={!enableButtons} className="run-button" onClick={onRunClick} title="Run report"><FontAwesomeIcon icon={faPlayCircle} size="lg" /></button>
        {hideDownloadButton ? null : <button disabled={!enableButtons || isDownloading} onClick={onDownloadClick} title="Download csv">{isDownloading ? <Loading /> : <FontAwesomeIcon icon={faFileDownload} size="lg" />}</button>}
      </div>
    </div>
  );
};

ReportControls.propTypes = {
  reportInputs: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onRunReport: PropTypes.func.isRequired,
  onDownloadReport: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool,
  hideDownloadButton: PropTypes.bool
};

export default ReportControls;
