import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar, Line } from 'react-chartjs-2';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import DropdownIndicator from 'src/common/DropdownIndicator';
import Loading from 'src/common/Loading';

import { INCIDENT_TYPE_COLORS, INCIDENT_TYPES, INCIDENT_TYPE_LABELS } from 'src/guard/constants';
import { incidentSettingEnabled } from 'src/guard/settingsHelpers';
import UserRiskRadarChart from 'src/guard/UserRiskRadarChart';
import withIncidentTypes from 'src/guard/withIncidentTypes';


const graphSelectOptions = [
  {value: 'DailyRisks1', label: 'Daily Risks (Bar Chart)'},
  {value: 'DailyRisks2', label: 'Daily Risks (Line Chart)'},
  {value: 'UserRisks', label: 'Risk per User'}
];

const IncidentGraphs = ({ incidents, startTime, settings, selectedIncidentType, isLoading, incidentTypes }) => {
  const [selectedGraph, setSelectedGraph] = useState(selectedIncidentType ? graphSelectOptions[0] : graphSelectOptions[2]);

  const graphData = useMemo(() => {
    const xAxisPeriod = selectedGraph.value === 'HourlyRisks' ? 'hours' : 'days';
    const xAxisTicks = Math.trunc(moment.duration(moment().diff(startTime, xAxisPeriod))) + 1;

    let incidentTypesByName = {};
    incidentTypes.forEach(iType => incidentTypesByName[iType.name] = iType);

    let incidentsByType = {};
    // Initialize an array of counts for each incident type
    for(const incidentType of Object.keys(INCIDENT_TYPES)) {
      incidentsByType[incidentType] = Array(xAxisTicks).fill(0);
    }
    // Update the counts for each incident type
    for(const incident of incidents) {
      const truncateTime = moment(incident.relatedMessage.messageTimestamp).endOf(xAxisPeriod);
      // This indexes the array of counts for each type using the diffence between the incident and the start time.
      // For example, if the indicent happens 2 days after the start time, then increment the count
      // at the index of 2.
      incidentsByType[incident.incidentType.name][truncateTime.diff(startTime, xAxisPeriod)]++;
    }
    let chartLabels = [];
    for(let i = 0; i < xAxisTicks; i++) {
      if(selectedGraph.value === 'HourlyRisks') {
        // Add labels every 6 hours
        chartLabels.push(i % 6 === 0 ? moment(startTime).add(i, xAxisPeriod).format('MM/DD/YYYY hh:mm A') : '');
      }
      else {
        chartLabels.push(moment(startTime).add(i, xAxisPeriod).format('MM/DD/YYYY'));
      }
    }

    return {
      datasets: Object.keys(INCIDENT_TYPES).filter(type => incidentSettingEnabled(settings, type)).map(incidentType => ({
        hidden: selectedIncidentType && selectedIncidentType !== incidentType,
        label: INCIDENT_TYPE_LABELS[incidentType],
        data: incidentsByType[incidentType],
        datalabels: { display: false },
        backgroundColor: INCIDENT_TYPE_COLORS[incidentType],
        lineTension: 0,
        showLine: (selectedGraph.value !== 'HourlyRisks') ? true : false
      })),
      labels: chartLabels
    };
  }, [incidents, startTime, settings, selectedGraph, selectedIncidentType, incidentTypes]);

  const graphOptions = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      }]
    },
    layout: {
      padding: {
        top: 25,
        bottom: 25,
        left: 25,
        right: 25
      }
    }
  };

  return (
    <div className="incident-graph-container col-12">
      <div className="incident-graph d-none d-md-block">
        <div className="incident-graph-controls">
          <div className="incident-graph-logo">
            <FontAwesomeIcon icon={faChartArea} size="2x" />
          </div>
          <Select
            className="graph-select"
            components={{DropdownIndicator}}
            defaultValue={selectedGraph}
            options={graphSelectOptions}
            onChange={option => setSelectedGraph(graphSelectOptions.find(g => g.value === option.value))}
          />
        </div>
        {
          isLoading
          ? <Loading largeSpinner={true} />
          : selectedGraph.value === 'DailyRisks1'
          ? <Bar data={graphData} options={graphOptions} />
          : selectedGraph.value === 'UserRisks'
          ? <UserRiskRadarChart incidents={incidents} settings={settings} modalClassName="incident-modal" />
          /* key is added to Line to force a re-render when selecting different line graphs */
          : <Line key={selectedGraph.value} data={graphData} options={graphOptions} />
        }
      </div>
      {/* display chart message when screen size is too small for charts */}
      <h5 className="d-xl-none d-lg-none d-md-none d-sm-block text-center py-4">To view charts please access your Guard Dashboard on a larger device.</h5>
    </div>
  );
};

IncidentGraphs.propTypes = {
  incidents: PropTypes.array.isRequired,
  startTime: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  selectedIncidentType: PropTypes.string
};

export default withIncidentTypes(IncidentGraphs);
