import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import domtoimage from 'dom-to-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import AdminPage from 'src/common/AdminPage';
import withModal from 'src/common/withModal';

import Report from 'src/report_dashboard/Report';
import { getEventImage, getEventTooltip, getSortableEventTime } from 'src/report_dashboard/donor_journey/donorEventUtils';
import DonorJourneyLegend from 'src/report_dashboard/donor_journey/DonorJourneyLegend';
import IndividualDonorJourney from 'src/report_dashboard/donor_journey/IndividualDonorJourney';


const DonorJourney = ({ displayModal }) => {
  const chartRef = useRef();
  const journeyRef = useRef();

  const downloadJourney = blob => {
    const a = document.createElement('a');
    a.textContent = 'download';
    a.download = 'journey.png';
    a.href = URL.createObjectURL(blob);
    a.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    }));
  };

  return (
    <AdminPage title="DONOR JOURNEY">
      <div className="donor-journey">
        <Report reportID="70" clearOnInputChange={false} hideDownloadButton={true}>
          {reportData => {
            let chartDataSets = [];
            const donorsByNumber = {};

            const eventsByDonor = {};
            reportData.rows.forEach(donorEvent => (eventsByDonor[donorEvent[0]] = eventsByDonor[donorEvent[0]] || []).push(donorEvent));
            let donorNumber = 1;
            let earliestMonth = null;
            let latestMonth = null;
            const tooltipsByDonor = {};
            // sort from Z to A so names later in the alphabet have a lower donorNumber
            const donorNames = Object.keys(eventsByDonor).sort().reverse();
            for(const donor of donorNames) {
              const pointStyles = [];
              const donorData = [];
              for(let i = 0; i < eventsByDonor[donor].length; i++) {
                const donorEvent = eventsByDonor[donor][i];
                const eventTime = getSortableEventTime(donorEvent);
                donorData.push({
                  x: eventTime,
                  y: donorNumber
                });
                if(!earliestMonth || moment(eventTime).startOf('month') < earliestMonth) {
                  earliestMonth = moment(eventTime).startOf('month');
                }
                if(!latestMonth || moment(eventTime).endOf('month') > latestMonth) {
                  latestMonth = moment(eventTime).endOf('month');
                }
                pointStyles.push(getEventImage(eventsByDonor[donor], i));
                (tooltipsByDonor[donor] = tooltipsByDonor[donor] || []).push(getEventTooltip(donorEvent));
              }
              chartDataSets.push({
                label: donor,
                data: donorData,
                fill: false,
                datalabels: { display: false },
                pointStyle: pointStyles,
                pointRadius: 10,
                pointHoverRadius: 10
              });
              donorsByNumber[donorNumber] = donor;
              donorNumber++;
            }

            return (
              <div>
                <DonorJourneyLegend />
                <Line
                  key={donorNumber}
                  ref={r => chartRef.current = r}
                  height={Math.min(donorNumber*10, 10000)}
                  data={{
                    datasets: chartDataSets
                  }}
                  options={{
                    legend: { display: false },
                    scales: {
                      xAxes: [{
                        type: 'time',
                        time: {
                          unit: 'day'
                        },
                        ticks: {
                          min: earliestMonth,
                          max: latestMonth
                        }
                      }],
                      yAxes: [{
                          ticks: {
                            max: donorNumber,
                            stepSize: 1,
                            callback: label => {
                              if( label in donorsByNumber) {
                                return donorsByNumber[label];
                              }
                              return '';
                            }
                        }
                      }],
                    },
                    hover: {
                      onHover: e => {
                        if(chartRef.current.chartInstance.getElementAtEvent(e).length) {
                          e.target.style.cursor = 'pointer';
                        } else {
                          e.target.style.cursor = 'default';
                        }
                      }
                    },
                    tooltips: {
                      displayColors: false,
                      callbacks: {
                        label: (item, data) => {
                          return tooltipsByDonor[data.datasets[item.datasetIndex].label][item.index];
                        }
                      }
                    }
                  }}
                  getElementAtEvent={element => {
                    if(element.length > 0) {
                      const { datasets } = element[0]._chart.tooltip._data;
                      const datasetIndex = element[0]._datasetIndex;
                      displayModal(
                        <span>
                          <button
                            onClick={_ => journeyRef && domtoimage.toBlob(journeyRef.current).then(downloadJourney)}
                            className="download-button ml-3 small"
                          >
                            <FontAwesomeIcon icon={faFileDownload} size="sm" />
                          </button>
                        </span>,
                        <IndividualDonorJourney
                          ref={r => journeyRef.current = r}
                          title={`${datasets[datasetIndex].label}'s Journey`}
                          donorEvents={eventsByDonor[datasets[datasetIndex].label]}
                        />
                      );
                    }
                  }}
                />
              </div>
            );
          }}
        </Report>
      </div>
    </AdminPage>
  );
};

export default withModal(DonorJourney);
