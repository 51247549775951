import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import 'src/common/styles/delete-button.scss';

const DeleteButton = ({ clickAction }) => (
  <span className="delete-button ml-2" onClick={clickAction}>
    <FontAwesomeIcon icon={faTimesCircle} />
  </span>
);

export default DeleteButton;
