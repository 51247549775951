export const commonChartOptions = (displayLegend, chartRef) => ({
  maintainAspectRatio: false,
  legend: {
    display: displayLegend,
    onHover: e => {
      e.target.style.cursor = 'pointer';
    }
  },
  plugins: {
    datalabels: {
      color: '#FFFFFF',
    }
  },
  hover: {
    onHover: e => {
      if(chartRef.current.chartInstance.getElementAtEvent(e).length) {
        e.target.style.cursor = 'pointer';
      } else {
        e.target.style.cursor = 'default';
      }
    }
  }
});
