import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { sortBy } from 'lodash';

import CustomDropdown from 'src/common/CustomDropdown';
import QueryWrapper from 'src/common/QueryWrapper';
import withCurrentUser from 'src/common/withCurrentUser';
import withSelectedPortfolio from 'src/fundraiser/withSelectedPortfolio';

import 'src/common/styles/portfolio-dropdown.scss';

import { loader } from 'graphql.macro';
const GET_MANAGED_PORTFOLIOS = loader('../graphql/fundraiser/get_managed_portfolios.gql');


const portfolioSelectOptionText = p => p ? p.fundraiserUser.firstName + ' ' + p.fundraiserUser.lastName + ' - ' + (p.displayName || p.portfolioType.name) : '';

const PortfolioDropdown = ({ currentUser, selectedPortfolioID }) => {
  const client = useApolloClient();
  const onPortfolioChange = (value) => client.writeData({ data: { selectedPortfolioID: value.value } });

  return (
    <div className="portfolio-container">
      <QueryWrapper queryResult={useQuery(GET_MANAGED_PORTFOLIOS)}>
        {portfolioData => {
          const allPortfolios = portfolioData.managedPortfolios;
          const userPortfolios = allPortfolios.filter(p => p.fundraiserUser.id === currentUser.id);
          const managedPortfolios = allPortfolios.filter(p => p.fundraiserUser.id !== currentUser.id);
          const options = [
            {
              label: "Your Portfolios",
              options: sortBy(userPortfolios, portfolioSelectOptionText).map(p => {
                return {
                  label: portfolioSelectOptionText(p),
                  value: p.id,
                }
              })
            },
          ];

          if (managedPortfolios.length) {
            const managedPortfoliosGroup = {
              label: "Managed Portfolios",
              options: sortBy(managedPortfolios, portfolioSelectOptionText).map(p => {
                return {
                  label: portfolioSelectOptionText(p),
                  value: p.id,
                }
              })
            }
            if (!selectedPortfolioID) {
              selectedPortfolioID = managedPortfoliosGroup.options[0].value;
              client.writeData({ data: { selectedPortfolioID: selectedPortfolioID } });
            }

            options.push(managedPortfoliosGroup);
          }
          
          return portfolioData.managedPortfolios.length ?
            <div className="portfolio-dropdown">
              <span className="portfolio-title">Fundraiser Portfolio</span>
              <CustomDropdown
                options={options} 
                onChange={onPortfolioChange} 
                isSearchable={false}
                name="Portfolio"
                value={{label: portfolioSelectOptionText(allPortfolios.find(p => p.id === selectedPortfolioID)), value: selectedPortfolioID}}
              />
            </div>
            : null;
        }}
      </QueryWrapper>
    </div>
  );
}

export default withSelectedPortfolio(withCurrentUser(PortfolioDropdown));
