import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

import 'src/guarantee_calculator/styles/week.scss';

import { onContainerBlur } from 'src/common/componentHelpers';
import { useEditMode } from 'src/common/useEditMode';
import { calculateWeeklyTotal, nextID } from 'src/guarantee_calculator/calculatorHelpers';
import FollowUp, { FollowUpModel } from 'src/guarantee_calculator/FollowUp';


// id - a unique id
// number - the position of the week in the overall plan
// count - how many FDs to perform
// countPeriod - how often to perform `count` FDs
// followUps - array of FollowUpModels associated with the FDs in this week
export class WeekModel {
  constructor(id, number, count, countPeriod, followUps) {
    this.id = id;
    this.number = number;
    this.count = count;
    this.countPeriod = countPeriod;
    this.followUps = followUps;
  }
  calcWeeklyCount() {
    return calculateWeeklyTotal(this.count, this.countPeriod);
  }
};


const Week = ({ week, onCopy, onDelete, onChange }) => {
  const [mode, setMode, editDivRef] = useEditMode(week.count === 0);
  const [fdCount, setFDCount] = useState(week.count || 0);
  const [fdCountPeriod, setFDCountPeriod] = useState(week.countPeriod || 'DAY');
  const [followUps, setFollowUps] = useState(week.followUps || []);

  useEffect(() => {
    onChange(week.id, new WeekModel(week.id, week.number, fdCount, fdCountPeriod, followUps));
  }, [week.id, week.number, fdCount, fdCountPeriod, followUps, onChange])

  const onFDInputBlur = _ => setMode('READ');

  const addFollowUp = () => {
    setFollowUps(prevFUPs => {
      const weeksOffset = prevFUPs.length ? prevFUPs[prevFUPs.length-1].weeksOffset + 1 : 1;
      return [...prevFUPs, new FollowUpModel(nextID(prevFUPs), fdCount, fdCountPeriod, 'Email', weeksOffset, prevFUPs.length+1)];
    });
  };

  const onDeleteFollowUp = fupID => {
    setFollowUps(prevFUPs => {
      const newFUPs = prevFUPs.filter(fup => fup.id !== fupID)
      return newFUPs.map((fup, index) => {
        fup.actionNumber = index + 1;
        return fup;
      });
    });
  };

  const onChangeFollowUp = useCallback(updatedFUP => {
    setFollowUps(prevData => {
      const newData = [...prevData];
      const index = newData.findIndex(fup => fup.id === updatedFUP.id);
      newData[index] = updatedFUP;
      return newData;
    });
  }, []);

  return (
    <div className="week">
      <div>
        <div className="week-title">
          Week {week.number}
        </div>
        <FontAwesomeIcon className="week-copy" icon={faCopy} size="sm" onClick={_ => onCopy(week)} />
        <FontAwesomeIcon className="week-remove" icon={faTimes} size="sm" onClick={_ => onDelete(week.id)} />
      </div>
      <hr />
      {mode === 'EDIT' ?
        <div ref={editDivRef} className="fd-input" tabIndex={0} onBlur={ev => onContainerBlur(ev, onFDInputBlur)}>
          <input className="form-control" autoComplete="off" type="number" id={`fd-input-${week.number}`} value={fdCount || 0} onChange={ev => setFDCount(parseInt(ev.target.value, 10))}></input>
          FDs per
          <select className="form-control" value={fdCountPeriod} onChange={ev => setFDCountPeriod(ev.target.value)}>
            <option value="DAY">day</option>
            <option value="WEEK">week</option>
          </select>
        </div> :
        <div className="fd-read-only" onClick={_ => setMode('EDIT')}>
          <ul>
            <li>Send {fdCount} First Draft Messages per {fdCountPeriod.toLowerCase()}</li>
          </ul>
        </div>
      }
      <div className="follow-ups-container">
        {followUps.map(fup =>
          <FollowUp initialFollowUp={fup} key={`fup-${week.number}-${fup.id}`} weekNumber={week.number} onDelete={onDeleteFollowUp} onChange={onChangeFollowUp} />
        )}
        <div className="follow-up add-button" onClick={addFollowUp}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    </div>
  );
};

Week.propTypes = {
  week: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Week;
