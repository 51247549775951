import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import QueryWrapper from 'src/common/QueryWrapper';

import { loader } from 'graphql.macro';
const GET_GUARD_SETTINGS = loader('../graphql/guard/get_guard_settings.gql');


// Fetches the values of the guard settings for the user and sets props
// on the wrapped component with the name of the setting key and the value of the setting
const withGuardSettings = WrappedComponent => {
  const SettingComponent = props => {
    return (
      <QueryWrapper queryResult={useQuery(GET_GUARD_SETTINGS)}>
        {data =>
          <WrappedComponent
            attachment_size_threshold={data.attachment_size_threshold_setting.value}
            attachment_type_check_archive={data.attachment_type_check_archive_setting.value}
            attachment_type_check_spreadsheet={data.attachment_type_check_spreadsheet_setting.value}
            received_deceptive_link={data.received_deceptive_link_setting.value}
            received_from_replyto_mismatch={data.received_from_replyto_mismatch_setting.value}
            sent_anonymous_donor_info={data.sent_anonymous_donor_info_setting.value}
            sent_sensitive_info_ach={data.sent_sensitive_info_ach_setting.value}
            sent_sensitive_info_ssn={data.sent_sensitive_info_ssn_setting.value}
            {...props}
          />
        }
      </QueryWrapper>
    );
  }

  return SettingComponent;
}

export default withGuardSettings;
