import React from 'react';

import SettingsLoading from 'src/fundraiser/setting/SettingsLoading';
import withSelectedPortfolio from 'src/fundraiser/withSelectedPortfolio';

import { loader } from 'graphql.macro';
const GET_FUNDRAISER_SETTINGS = loader('src/graphql/fundraiser/get_fundraiser_settings.gql');


// Fetches the value of the fundraiser settings for the user
const withFundraiserSettings = WrappedComponent => {
  const SettingComponent = props => {
    return (
      <SettingsLoading query={GET_FUNDRAISER_SETTINGS} variables={{ portfolioId: props.selectedPortfolioID }}>
        {(data, refetchSettings, setIsSaving) =>
          <WrappedComponent settingValues={data} refetchSettings={refetchSettings} setIsSaving={setIsSaving} {...props} />
        }
      </SettingsLoading>
    );
  }

  return withSelectedPortfolio(SettingComponent);
}

export default withFundraiserSettings;
