import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import 'src/page-not-found.scss';

import AdminPage from 'src/common/AdminPage';


const PageNotFound = () => (
  <AdminPage>
    <div className="page-not-found pt-5">
      <center>
        <h1>404</h1>
        <h3>SEEMS LIKE YOU ARE LOST<br/>LET US HELP YOU GET BACK!</h3>
        <Link to="/">
          <button className="btn home-btn">
            <FontAwesomeIcon icon={faHome} size="4x" />
          </button>
        </Link>
      </center>
    </div>
  </AdminPage>
);

export default PageNotFound;
