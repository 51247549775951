import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { usePrevious } from 'src/common/usePrevious';


const CheckboxSetting = ({ label, color, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const firstRender = useRef(true);
  const prevValue = usePrevious(value);
  useEffect(() => {
    if(!firstRender.current && prevValue !== value) {
      onChange(value);
    }
    firstRender.current = false;
  }, [prevValue, value, onChange]);

  const styling = {};
  if(color && value) {
    styling['backgroundColor'] = color;
  }

  return (
    <div className="settings-checkbox" onClick={() => setValue(v => !v)}>
      <input
        type="checkbox"
        value={value}
        checked={value}
        onChange={ev => setValue(ev.target.checked)}
      />
      <label className={classnames(color)}>{label}</label>
      <div className="settings-checkbox-input" style={styling} />
    </div>
  );
};

export default CheckboxSetting;
