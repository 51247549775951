import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import classnames from 'classnames';
import moment from 'moment-timezone';

import 'src/report_dashboard/styles/report.scss';

import { buildCSVFromTable, buildCSVFromPieChart, buildCSVFromBarGraph, downloadCSV } from 'src/common/csvHelpers';
import CollapseText from 'src/common/CollapseText';
import Loading from 'src/common/Loading';
import QueryWrapper from 'src/common/QueryWrapper';
import BarGraphReport from 'src/report_dashboard/BarGraphReport';
import ReportControls from 'src/report_dashboard/ReportControls';
import PieChartReport from 'src/report_dashboard/PieChartReport';
import TableReport from 'src/report_dashboard/TableReport';

import { loader } from 'graphql.macro';
const GET_REPORT = loader('../graphql/report_dashboard/get_report.gql');
const RUN_REPORT = loader('../graphql/report_dashboard/run_report.gql');
const SEND_REPORT = loader('../graphql/report_dashboard/send_report.gql');
const GET_REPORT_RESULT = loader('../graphql/report_dashboard/get_report_result.gql')
const DELETE_REPORT_JOB = loader('../graphql/report_dashboard/delete_report_job.gql');


Chart.pluginService.register(ChartDataLabels);
Chart.scaleService.updateScaleDefaults('linear', { ticks: { min: 0 } });

const Report = ({ reportID, onToggleReport, clearOnInputChange, hideDownloadButton, children }) => {
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [reportError, setReportError] = useState('');
  const [dataIsDownloading, setDataIsDownloading] = useState(false);
  const [downloadExistingData, setDownloadExistingData] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [reportInputs, setReportInputs] = useState([]);
  const [reportTitle, setReportTitle] = useState('');
  const [jobName, setJobName] = useState('');
  const [timeoutID, setTimeoutID] = useState();
  const [loadingMessage, setLoadingMessage] = useState('');

  const [deleteReportJob] = useMutation(DELETE_REPORT_JOB);
  const [runReport] = useMutation(RUN_REPORT);
  const [sendReport] = useMutation(SEND_REPORT);

  // poll for report data when report is run
  const [getReportResult, {loading, data, stopPolling}] = useLazyQuery(GET_REPORT_RESULT, {
    pollInterval: 2000,
  });

  // when changing to a different report, clear all the data
  useEffect(() => setReportData(null), [reportID]);

  // if data exists, stop polling and display data
  useEffect(() => {
    // check whether data exists
    if (!!data && data.reportResult && data.reportResult.data) {
      clearTimeout(timeoutID);
      stopPolling();
      dataIsLoading && displayData(data.reportResult.data);
      dataIsDownloading && downloadReportCSV(data.reportResult.data);
      deleteReportJob({
        variables: {
          job_name: jobName
        }
      });
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  // stop polling if error
  useEffect(() => {
    if (reportError) {
      stopPolling();
    }
  }, [reportError]); // eslint-disable-line react-hooks/exhaustive-deps

  // populate the data when the report finished running
  const displayData = data => {
    setDataIsLoading(false);
    setReportData(data);
  }

  const downloadData = (csv, title) => {
    downloadCSV(csv, title);
    setDataIsDownloading(false);
    setDownloadExistingData(false);
  }

  const downloadReportCSV = (data) => {
    if (data.__typename === 'TableResultType') {
      downloadData(buildCSVFromTable(data), reportTitle);
    } else if (data.__typename === 'PieResultType') {
      downloadData(buildCSVFromPieChart(data), reportTitle);
    } else if (data.__typename === 'BarResultType') {
      downloadData(buildCSVFromBarGraph(data), reportTitle);
    }
  }

  // when a new report is selected, update the inputs and initialize the input values
  const onGetReport = data => setReportInputs(data.report.inputs);

  const onReportTimeoutError = (reportID, inputValues, inputArrays) => {
    setReportData(null);
    setDataIsLoading(false);
    setDataIsDownloading(false);
    setReportError("The report took too long to run. We'll email you the results instead.");
    sendReport({ variables: { id: reportID, input_values: inputValues, input_arrays: inputArrays } });
  }

  const getReportData = (reportInputValuesByID, isDownload, args) => {
    setLoadingMessage('');
    setReportError('');
    if (isDownload) {
      setDataIsDownloading(true);
      setReportTitle(args["title"]);
      if (reportData !== null) {
        // don't refetch the data if it is being downloaded, use the data being displayed
        setDownloadExistingData(true);
        return;
      }
    } else {
      setDataIsLoading(true);
    }

    // input values must be in the same order as the inputs
    const inputValues = [];
    const inputArrays = [];
    let inputCount = 0;
    for (const reportInput of reportInputs) {
      if (reportInput.input.fieldType === 'MULTISELECT') {
        inputArrays.push({ index: inputCount, value: reportInputValuesByID[reportInput.id] ? reportInputValuesByID[reportInput.id].map(input => input.value) : [] });
      } else {
        let inputValue = '';
        if (reportInput.input.fieldType === 'DATE') {
          // input_values is a list of strings, so convert the date to a string (i.e. YYYY-MM-DD)
          // To allow dates to be handled correctly on the backend, convert them to UTC
          // if the date is optional, add an empty string which should be handled by the report query
          const date = reportInputValuesByID[reportInput.id];
          if (date) {
            inputValue = moment(date).utc().format("YYYY-MM-DD H:mm:ss");
          }
        } else {
          inputValue = reportInputValuesByID[reportInput.id];
        }
        inputValues.push({ index: inputCount, value: inputValue });
      }
      inputCount++;
    }
    runReport({
      variables: {
        id: reportID,
        input_values: inputValues,
        input_arrays: inputArrays
      }
    }).then(data => {
      const job_name = data.data['runReport'];
      setReportData(null);
      setJobName(job_name);
      getReportResult({
        variables: {
          job_name: job_name
        }
      });
    })
    .catch(error => setReportError(error.message));
    
    // timeout report if more than 5 minutes
    setTimeoutID(setTimeout(() => onReportTimeoutError(reportID, inputValues, inputArrays), 5*60*1000));
    // additional loading message if report runs for more than 30 seconds
    setTimeout(() => { setLoadingMessage('This report may take a few minutes to run') }, 30*1000);
  }

  return (
    <div className="dashboard-report">
      <QueryWrapper queryResult={useQuery(GET_REPORT, { variables: { id: reportID }, onCompleted: onGetReport })}>
        {q => {
          const report = q.report;
          return (
            <>
              <h2 className="report-title">{report.title}</h2>
              {report.description && <CollapseText id={report.id} title="Description" text={report.description} />}
              <ReportControls
                reportInputs={report.inputs}
                onChange={() => clearOnInputChange ? setReportData(null) : null}
                onRunReport={values => getReportData(values, false)}
                onDownloadReport={values => getReportData(values, true, { title: report.title })}
                isDownloading={dataIsDownloading}
                hideDownloadButton={hideDownloadButton} />
              {
                dataIsLoading && !reportError ?
                  <div>
                    { loadingMessage ? <p>{ loadingMessage }</p> : '' }
                    <div className="report-results-wrapper shadow"><Loading largeSpinner={true} /></div>
                  </div>
                  : reportData ?
                    children ? children(reportData)
                      : <div className={classnames('report-results-wrapper shadow', reportData.__typename !== 'TableResultType' ? 'p-4' : null)}>
                        {
                          reportData.__typename === 'TableResultType' ?
                            <TableReport
                              reportData={reportData}
                              downloadTable={downloadExistingData}
                              onDownloadReady={csv => downloadData(csv, report.title)}
                              onToggleReport={onToggleReport} />
                            : reportData.__typename === 'PieResultType' ?
                              <PieChartReport
                                slices={reportData.slices}
                                downloadData={downloadExistingData}
                                onDownloadReady={csv => downloadData(csv, report.title)} />
                              : reportData.__typename === 'BarResultType' ?
                                <BarGraphReport
                                  labels={reportData.labels}
                                  barGroups={reportData.barGroups}
                                  downloadData={downloadExistingData}
                                  onDownloadReady={csv => downloadData(csv, report.title)} />
                                : null
                        }
                      </div>
                    : null
              }
              {
                reportError ? <div className="text-danger">{reportError}</div> : null
              }
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
};

Report.propTypes = {
  reportID: PropTypes.string,
  onToggleReport: PropTypes.func,
  // when clearOnInputChange is true, the report data will be cleared out when an input changes
  clearOnInputChange: PropTypes.bool,
  hideDownloadButton: PropTypes.bool,
  // if children is provided, this function will be called with the report data,
  // otherwise, the graph is rendered by this component
  children: PropTypes.func
};

Report.defaultProps = {
  clearOnInputChange: true,
  hideDownloadButton: false
};

export default Report;
