import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'src/report_dashboard/styles/pie-chart-report.scss';

import PieChartReport from 'src/report_dashboard/PieChartReport';


// This is a pie chart that is built from a single column within a table of data.
// It also includes a drop-down menu for selecting a second column of data to group
// the first column by. This is valid if the column being graphed is a set of numbers.
// Those numbers are summed for each item in the second column.
const DynamicPieChartReport = ({ colIndex, data }) => {
  const [groupByColIndex, setGroupByColIndex] = useState('');

  const getSlices = _ => {
    if(groupByColIndex) {
      const sliceSums = {};
      data.rows.forEach(row => {
        row[groupByColIndex] in sliceSums
          ? sliceSums[row[groupByColIndex]] += Number(row[colIndex])
          : sliceSums[row[groupByColIndex]] = Number(row[colIndex]);
      });
      // Round to 2 decimal places to handle precision errors in float addition
      return Object.keys(sliceSums).map(key => ({ name: key, value: sliceSums[key].toFixed(2).toString() }));
    } else {
      const colData = data.rows.map(row => row[colIndex]);
      const counts = {};
      colData.forEach(d => counts[d] = 1 + (counts[d] || 0));
      return Object.keys(counts).map(key => ({ name: key, value: counts[key].toString() }));
    }
  };

  const getGroupByOptions = () => {
    return data.columns.reduce((acc, column, index) => {
      if(index !== colIndex) {
        acc.push({ label: column.name, value: index });
      }
      return acc;
    }, []);
  };

  const onGroupBySelected = clickEvent => setGroupByColIndex(clickEvent.target.value);

  return (
    <div className="pie-chart-dynamic report-results">
      {data.rows.length > 0 && !isNaN(data.rows[0][colIndex]) ?
        <div className="group-by-select">
          Group By:
          <select onChange={onGroupBySelected} value={groupByColIndex}>
            <option value="">--- Select ---</option>
            {getGroupByOptions().map((opt, i) => <option key={opt.value} value={opt.value}>{opt.label}</option>)}}
          </select>
        </div>
        : null
      }
      <PieChartReport slices={getSlices()} />
    </div>
  );
};

DynamicPieChartReport.propTypes = {
  colIndex: PropTypes.number,
  data: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array
  })
};

export default DynamicPieChartReport;
