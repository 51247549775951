import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Link } from 'react-router-dom';


const LinkWrapper = ({ to, className, children }) => {
  // Use an `<a>` tag when going to an external site
  // Setting require_tld to false allows 'localhost' to be a valid URL domain
  if(validator.isURL(to, { require_protocol: true, require_tld: false })) {
    return <a href={to} target="_blank" className={className} rel="noreferrer">{children}</a>;
  } else {
    return <Link to={to} className={className}>{children}</Link>;
  }
};

LinkWrapper.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

export default LinkWrapper;
