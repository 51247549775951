import React from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import ApolloLinkTimeout from 'apollo-link-timeout';

import AppRouter from 'src/AppRouter';

import introspectionQueryResultData from './graphql/fragmentTypes.json';
import 'src/app.scss'


const App = () => {
  const history = useHistory();

  const httpLink = createHttpLink({
      uri: process.env.REACT_APP_DJANGO_SERVER + 'graphql',
      credentials: 'include',
  });

  // Heroku will kill a request after 30 seconds giving a generic network error.
  // Setting our own timeout for 29 seconds will allow us to handle this better.
  const timeoutLink = new ApolloLinkTimeout(29000);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if(graphQLErrors.some(err => err.message === 'Authentication credentials were not provided')) {
        history.push('/login');
      }
    }
  });

  // As of Apollo Client 2.6, this is the recommended approach for
  // using fragments with union and interface GraphQL types.
  const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

  const cache = new InMemoryCache({ fragmentMatcher });
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, timeoutLink, httpLink]),
    resolvers: {}
  });

  // Multiple pages allow a portfolio to be selected. The PortfolioDropdown
  // component is used on all those pages. It writes the selected value
  // to the client-side cache so that all the pages can read from it.
  cache.writeData({
    data: {
      selectedPortfolioID: ''
    }
  })

  return (
    <ApolloProvider client={client}>
      <AppRouter />
    </ApolloProvider>
  );
}

export default App;
