import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import QueryWrapper from 'src/common/QueryWrapper';

import { loader } from 'graphql.macro';
const GET_CURRENT_PORTFOLIO = loader('../graphql/fundraiser/get_current_portfolio.gql');
const GET_SELECTED_PORTFOLIO = loader('../graphql/client_state/get_selected_portfolio.gql');


const withSelectedPortfolio = WrappedComponent => {
  const Component = props => {
    const { data } = useQuery(GET_SELECTED_PORTFOLIO);

    return (
      <QueryWrapper queryResult={useQuery(GET_CURRENT_PORTFOLIO)}>
        {portfolioData => <WrappedComponent selectedPortfolioID={data.selectedPortfolioID || (portfolioData.currentPortfolio && portfolioData.currentPortfolio.id) || ''} {...props} />}
      </QueryWrapper>
    );
  };

  return Component;
}

export default withSelectedPortfolio;
