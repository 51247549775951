import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import domtoimage from 'dom-to-image';

import 'src/guarantee_calculator/styles/results.scss';

import { numberToOrdinal } from 'src/guarantee_calculator/calculatorHelpers';


const WEEKS_PER_ROW = 4;

// This renders the results from the Guarantee Calculator in rows of up to 4 weeks
const Results = ({ weeks }) => {
  const resultsRef = useRef();

  const downloadResults = blob => {
    const a = document.createElement('a');
    a.textContent = 'download';
    a.download = 'results.png';
    a.href = URL.createObjectURL(blob);
    a.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    }));
  };

  const countsByWeekNumber = useMemo(_ => {
      const counts = {};
      const numWeeks = weeks.length;
      for(let i = 0; i < numWeeks; i++) {
        counts[weeks[i].number] = {
          weeklyFDCount: 0,
          weeklyFollowUpCount: 0,
          toDateFDCount: 0,
          toDateFollowUpCount: 0
        };
      }

      for(let i = 0; i < numWeeks; i++) {
        const week = weeks[i];
        // set the current week's FD count
        counts[week.number].weeklyFDCount = week.calcWeeklyCount();
        // add the current week's FD count to each future week's to date count
        for(let j = i; j < numWeeks; j++) {
          counts[weeks[j].number].toDateFDCount += week.calcWeeklyCount();
        }

        // for each of this week's follow-ups, add their counts to the weeks
        // that they occur
        week.followUps.forEach(fup => {
          const followUpWeekNumber = week.number + fup.weeksOffset;
          // check if the week exists, because it may not have been added yet
          if(counts[followUpWeekNumber]) {
            counts[followUpWeekNumber].weeklyFollowUpCount += fup.calcWeeklyCount();

            for(let j = i; j < numWeeks; j++) {
              if(weeks[j].number >= followUpWeekNumber) {
                counts[weeks[j].number].toDateFollowUpCount += fup.calcWeeklyCount();
              }
            }
          }
        });
      }

      return counts;
  }, [weeks]);

  return (
    <div className="calculator-results-wrapper">
      <button
        onClick={_ => resultsRef && domtoimage.toBlob(resultsRef.current).then(downloadResults)}
        className="download-button">
        <FontAwesomeIcon icon={faFileDownload} size="lg" />
      </button>
      <div className="calculator-results" ref={r => resultsRef.current = r}>
        {weeks.map(week => {
          const weekCounts = countsByWeekNumber[week.number];

          return (
            <div key={`week-${week.number}-results`} className="week-results">
              <div className="results-title">
                <div className="results-week-number">Week {week.number}</div>
                <div className="results-banner" />
                <div className="results-banner-triangle" />
              </div>
              <div className="results-activity">
                <ul>
                  {week.count > 0 && <li>Send {week.count} First Draft Messages{week.countPeriod.toLowerCase() === 'day' ? ' per day' : ''}</li>}
                  {weeks.filter(prevWeek => prevWeek.number <= week.number).map(prevWeek => prevWeek.followUps.map(fup =>
                    prevWeek.number + fup.weeksOffset === week.number ?
                    <li key={`week-${week.number}-results-fup-${fup.id}`}>
                      {`${fup.type === 'Call' ? 'Make' : 'Send'} ${fup.count} ${fup.actionNumber < prevWeek.followUps.length ? numberToOrdinal(fup.actionNumber+1) : 'final'} action follow-up ${fup.type}s${fup.countPeriod.toLowerCase() === 'day' ? ' per day' : ''} for outreach initiated in Week ${prevWeek.number}`}
                    </li>
                    : null
                  ))}
                </ul>
              </div>
              <div className="projected-results">
                <div className="projected-results-title">Projected Results</div>
                {weekCounts.weeklyFDCount > 0 || weekCounts.weeklyFollowUpCount > 0 ?
                  <>
                    <div className="projected-results-subtitle">This Week:</div>
                    {weekCounts.weeklyFDCount > 0 && <li className="projected-results-result">Reached {weekCounts.weeklyFDCount} new prospects</li>}
                    {weekCounts.weeklyFollowUpCount > 0 && <li className="projected-results-result">Up to {weekCounts.weeklyFollowUpCount} follow-up actions</li>}
                  </>
                  : null
                }
                {weekCounts.toDateFDCount > 0 || weekCounts.toDateFollowUpCount > 0 ?
                  <>
                    <div className="projected-results-subtitle">To Date:</div>
                    {weekCounts.toDateFDCount  > 0 && <li className="projected-results-result">Reached {weekCounts.toDateFDCount } prospects</li>}
                    {weekCounts.toDateFollowUpCount > 0 && <li className="projected-results-result">Up to {weekCounts.toDateFollowUpCount} follow-up actions</li>}
                  </>
                  : null
                }
              </div>
            </div>
          );
        }).reduce((rows, weekContent, weekIndex) => {
          if(weekIndex % WEEKS_PER_ROW === 0) {
            rows.push([weekContent]);
          } else {
            rows[rows.length-1].push(weekContent);
          }
          return rows;
        }, []).map((row, index) => {
          return (
            <div className="week-results-row" key={`week-results-row-${index}`}>
              {row}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Results.propTypes = {
  weeks: PropTypes.array.isRequired
};

export default Results;
