import React, { useState, useRef } from 'react';

import { DateRange } from 'react-date-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import {dateStringToLocalTime, dateToString, localTimeToDateString} from 'src/common/dateHelpers';
import DeleteButton from 'src/common/DeleteButton';
import useOutsideClick from 'src/common/useOutsideClick';
import Setting from 'src/fundraiser/setting/Setting';

import 'src/fundraiser/setting/styles/out-of-office-setting.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


export const dateRangeFromDates = ({ fromDate, toDate }) => {
  const dateRange = { key: 'selection' };
  if(fromDate) {
    dateRange.startDate = dateStringToLocalTime(fromDate);
  }
  if(toDate) {
    dateRange.endDate = dateStringToLocalTime(toDate);
  }
  return dateRange;
};

export const compareDateRanges = (date1, date2) => {
  if(
    (date1.startDate && date2.startDate && date1.startDate.getTime() !== date2.startDate.getTime())
    || (date1.startDate && !date2.startDate)
    || (!date1.startDate && date2.startDate)
  ) {
    return false;
  }
  if(
    (date1.endDate && date2.endDate && date1.endDate.getTime() !== date2.endDate.getTime())
    || (date1.endDate && !date2.endDate)
    || (!date1.endDate && date2.endDate)
  ) {
    return false;
  }
  return true;
};

const OutOfOfficeText = ({ fromDate, toDate }) => {
  if(fromDate && toDate) {
    return <><span className="date">{dateToString(fromDate)}</span> to <span className="date">{dateToString(toDate)}</span></>;
  }
  if(fromDate) {
    return <>starting <span className="date">{dateToString(fromDate)}</span></>;
  }
  if(toDate) {
    return <>until <span className="date">{dateToString(toDate)}</span></>;
  }
};

const OutOfOfficeSetting = ({ dateRange, hasUnsavedValue, onDateChange }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef();
  useOutsideClick(calendarRef, () => {
    if (showCalendar) setShowCalendar(false);
  });

  return (
    <div className="out-of-office-setting">
      <Setting
        id="out-of-office"
        title="Out Of Office"
        description="If you're planning to be out of the office and would like to pause your Raise emails select your out of office date range by clicking the calendar icon to the right."
        hasUnsavedValue={hasUnsavedValue}
      >
        <button onClick={() => setShowCalendar(!showCalendar)} className="ml-auto btn calendar d-inline-block">
          <FontAwesomeIcon icon={faCalendarAlt} />
        </button>
        <div className="calendar-container">
          {showCalendar ?
            <div ref={calendarRef}>
              <DateRange
                className="ml-2 range-calendar"
                editableDateInputs={true}
                onChange={item => onDateChange(localTimeToDateString(item.selection.startDate), localTimeToDateString(item.selection.endDate))}
                moveRangeOnFirstSelection={false}
                ranges={[dateRange]}
                startDatePlaceholder='From'
                endDatePlaceholder='To'
                minDate={new Date()}
              />
            </div>
            :
            null
          }
        </div>
        <div className="mt-1 col-12">
          {dateRange.startDate || dateRange.endDate ?
            <div className="range-text">
              <OutOfOfficeText fromDate={dateRange.startDate} toDate={dateRange.endDate} />
              <DeleteButton clickAction={() => onDateChange()} />
            </div>
            : null
          }
        </div>
      </Setting>
    </div>
  );
};

export default OutOfOfficeSetting;
