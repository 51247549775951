import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { IS_GRAVYTY_STAFF } from './graphql/user/staff.gql';

import withIsAuthenticated from 'src/common/withIsAuthenticated';
import PageNotFound from 'src/PageNotFound';
import QueryWrapper from 'src/common/QueryWrapper';
import LoginPage from 'src/login/LoginPage';
import Dashboard from 'src/dashboard/Dashboard';
import GuardDashboard from 'src/guard/GuardDashboard';
import DonorJourney from 'src/report_dashboard/donor_journey/DonorJourney';
import ReportsPage from 'src/report_dashboard/ReportsPage';
import GuaranteeCalculator from 'src/guarantee_calculator/GuaranteeCalculator';


const ProtectedRoute = ({ isAuthenticated, component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={props => !isAuthenticated ? (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

const AuthenticatedRoute = withIsAuthenticated(ProtectedRoute);

const AppRouter = () => {
  return (
    <QueryWrapper queryResult={useQuery(IS_GRAVYTY_STAFF, { fetchPolicy: 'network-only' })}>
      {(data, refetch) =>
        data.isGravytyStaff ?
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" render={() => <LoginPage refetchIsStaff={refetch} />} />
              <AuthenticatedRoute exact path="/" component={Dashboard} />
              <AuthenticatedRoute exact path="/guarantee/" component={GuaranteeCalculator} />
              <AuthenticatedRoute exact path="/guard/:incidentType?" component={GuardDashboard} />
              <AuthenticatedRoute exact path="/reports/" component={ReportsPage} />
              <AuthenticatedRoute exact path="/reports/donorjourney" component={DonorJourney} />
              <Route exact component={PageNotFound} />
            </Switch>
          </BrowserRouter>
          :
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" render={() => <LoginPage refetchIsStaff={refetch} />} />
              <AuthenticatedRoute exact path="/" component={Dashboard} />
              <AuthenticatedRoute exact path="/guard/:incidentType?" component={GuardDashboard} />
              <Route exact component={PageNotFound} />
            </Switch>
          </BrowserRouter>
      }
  </QueryWrapper>
  );
};

export default AppRouter;
