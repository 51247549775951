import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import 'src/report_dashboard/styles/reports-page.scss';

import AdminPage from 'src/common/AdminPage';
import { addKeyValueToObjectState, removeKeyFromObjectState } from 'src/common/stateHelpers';
import QueryWrapper from 'src/common/QueryWrapper';
import Report from 'src/report_dashboard/Report';

import { loader } from 'graphql.macro';
const GET_REPORT_TITLES = loader('../graphql/report_dashboard/get_report_titles.gql');


const ReportsPage = () => {
  const [selectedReportID, setSelectedReportID] = useState('');
  const [additionalReports, setAdditionalReports] = useState({});

  const onSelectReport = event => {
    setSelectedReportID(event.target.value);
    setAdditionalReports({});
  };

  const onToggleReport = (name, report) => {
    if(name in additionalReports) {
      setAdditionalReports(removeKeyFromObjectState(name));
    } else if (report) {
      setAdditionalReports(addKeyValueToObjectState(name, report));
    }
  };

  const onCloseReport = name => () => {
    onToggleReport(name);
  };

  return (
    <AdminPage title="VALUE REPORTING">
      <QueryWrapper queryResult={useQuery(GET_REPORT_TITLES)}>
        {data => (
          <div className="reports-page">
            <select className="report-select" value={selectedReportID} onChange={onSelectReport}>
              <option value="" disabled>Select a report</option>
              {data.reports.filter(r => r.active).sort((a,b) => a.title < b.title ? -1 : 1).map(report => <option key={report.id} value={report.id}>{report.title}</option>)}
              <optgroup label="Deactivated reports">
                {data.reports.filter(r => !r.active).sort((a,b) => a.title < b.title ? -1 : 1).map(report => <option key={report.id} value={report.id}>{report.title}</option>)}
              </optgroup>
            </select>
            {selectedReportID && <Report reportID={selectedReportID} onToggleReport={onToggleReport} />}
            {Object.keys(additionalReports).map((name, index) =>
              <div className="dashboard-report" key={`additional-report-${index}`}>
                <h2 className="report-title">{name}</h2>
                <div className="report-results-wrapper shadow p-4">
                  <FontAwesomeIcon className="close-button" icon={faTimesCircle} size="2x" onClick={onCloseReport(name)} />
                  {additionalReports[name]}
                </div>
              </div>
            )}
          </div>
        )}
      </QueryWrapper>
    </AdminPage>
  );
};

export default ReportsPage;
