import React from 'react';

import 'src/report_dashboard/styles/donor-journey-legend.scss';

import {
  birthdayImage,
  firstDraftImage,
  firstDraftSentImage,
  giftFirstSmallImage,
  giftLargeImage,
  giftSmallImage,
  giftSmallIncreaseImage,
  phoneImage,
  letterImage,
  stewardshipImage,
  textImage
} from 'src/report_dashboard/donor_journey/actionImages';


const fdLegendItems = [
  { name: 'First Draft Prompt', icon: firstDraftImage },
  { name: 'First Draft Sent', icon: firstDraftSentImage },
  { name: 'Birthday First Draft', icon: birthdayImage },
  { name: 'Stewardship First Draft', icon: stewardshipImage }
];

const giftLegendItems = [
  { name: 'Gift under $10K', icon: giftSmallImage },
  { name: 'Gift of $10K and above', icon: giftLargeImage },
  { name: 'First gift by donor', icon: giftFirstSmallImage },
  { name: 'Gift increase', icon: giftSmallIncreaseImage },
];

const actionLegendItems = [
  { name: 'Phone call to donor', icon: phoneImage },
  { name: 'Email/Letter sent to donor', icon: letterImage },
  { name: 'Text message sent to donor', icon: textImage }
];

const renderLegendItems = items => (
  <>
    {items.map(item =>
      <div key={item.name} className="col-auto my-2">
        <div className="flex-nowrap align-items-center">
          <span><img src={item.icon.src} alt="" /></span>
          <span className="text-nowrap">{item.name}</span>
        </div>
      </div>
    )}
  </>
);

const DonorJourneyLegend = () => {
  return (
    <div className="donor-journey-legend">
      <div className="container">
        <div className="row justify-content-center">
          {renderLegendItems(fdLegendItems)}
        </div>
        <div className="row justify-content-center">
          {renderLegendItems(giftLegendItems)}
        </div>
        <div className="row justify-content-center">
          {renderLegendItems(actionLegendItems)}
        </div>
      </div>
    </div>
  );
};

export default DonorJourneyLegend;
