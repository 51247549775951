import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import 'src/login/styles/login.scss'

import { LOGIN, IS_LOGGED_IN } from '../graphql/user/login.gql';

import Loading from 'src/common/Loading';


const LoginPage = ({ refetchIsStaff }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrors, setLoginErrors] = useState([]);
  const location = useLocation();
  const password_reset_link = `${process.env.REACT_APP_DJANGO_SERVER}user/password/reset`;

  // Don't use apollo cache when fetching this. We want to know what the server thinks.
  const { loading, error, data, refetch } = useQuery(IS_LOGGED_IN, { fetchPolicy: 'network-only' });

  const onLoginCompleted = ({ tokenAuth }) => {
    if(tokenAuth && tokenAuth.payload) {
      refetch();
      refetchIsStaff();
      if (!data.isAuthenticated) {
        setLoginErrors(['No access to MyGravyty'])
      }
    }
  }
  const onLoginError = error => {
    setLoginErrors(error.graphQLErrors.map(err => err.message));
  }
  const [login] = useMutation(LOGIN, { onCompleted: onLoginCompleted, onError: onLoginError });

  if (loading) return <Loading largeSpinner={true} />
  else if (error) return <p>Error: {error.message}</p>

  const handleLogin = ev => {
    // prevent submission from reloading the page
    ev.preventDefault();
    if(username && password) {
      login({ variables: { username, password } });
    }
  }

  if(data.isAuthenticated) {
    return <Redirect to={location && location.state ? location.state.from || '/' : '/'} />
  }

  return (
    <div className="d-flex justify-content-center">
      <form className="login-form input-group-lg shadow p-3 mb-5 bg-white rounded d-flex flex-column align-items-center" onSubmit={handleLogin}>
        <div className="login-logo">
          <img src="https://gravyty.s3.amazonaws.com/Raise/Raise%404x-resized.png" alt="Raise logo" />
        </div>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Username" onChange={e => setUsername(e.target.value)}  />
        </div>
        <div className="input-group mb-3">
          <input type="password" className="form-control" placeholder="Password" onChange={e => setPassword(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary btn-block mb-3 login">Log In</button>
        {loginErrors.map((err, index) => <div key={`err-${index}`} className="login-form-error text-danger">Error: {err}</div>)}
        <a href={password_reset_link}>Forgot your password?</a>
        <a href={password_reset_link}>First time login?</a>
      </form>
    </div>
  );
};

export default LoginPage;
