import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { usePrevious } from 'src/common/usePrevious';


const Setting = ({ icon, color, title, contents, hideToggle, defaultValue, onChange, dataTip, dataTipId, }) => {
  const [ toggleEnabled, setToggleEnabled ] = useState(defaultValue || false);
  const toggle = () => setToggleEnabled(oldValue => !oldValue);

  const firstRender = useRef(true);
  const prevValue = usePrevious(toggleEnabled);
  useEffect(() => {
    if(!firstRender.current && onChange && prevValue !== toggleEnabled) {
      onChange(toggleEnabled);
    }
    firstRender.current = false;
  }, [prevValue, toggleEnabled, onChange]);

  const styling = {};
  if(color) {
    styling['backgroundColor'] = color;
  }

  const tooltip = (
    <Tooltip id={dataTipId} className="settings-tip">
      <h5>{title}:</h5>
      <p>{dataTip}</p>
    </Tooltip>
  );

  return (
    <>
      <div className="row align-items-center no-gutters mb-2">
        <OverlayTrigger trigger="click" placement="left" rootClose overlay={tooltip}>
          <div className="incident-tip">
            <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
          </div>
        </OverlayTrigger>
        <div className="col-auto">
          <div className="setting-icon" style={styling}>
            <FontAwesomeIcon icon={icon}/>
          </div>
        </div>
        <div className="col-6 mr-auto">
          <div className="setting-title">
            {title}
          </div>
        </div>
        {!hideToggle &&
          <div className="col-auto">
            <div
              className={classnames('toggle-input', toggleEnabled ? 'toggle-enabled' : null)}
              onClick={toggle}
              style={styling}
            >
              <span className="toggle" />
            </div>
          </div>
        }
        {contents}
      </div>
    </>
  );
};

export default Setting;
