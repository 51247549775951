import React, { useRef } from 'react';
import { max, sum } from 'lodash';
import { Radar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import withModal from 'src/common/withModal';
import withOrgUsers from 'src/common/withOrgUsers';

import { INCIDENT_TYPE_COLORS, INCIDENT_TYPE_LABELS, INCIDENT_TYPES } from 'src/guard/constants';
import { incidentSettingEnabled } from 'src/guard/settingsHelpers';


const UserRiskRadarChart = ({ users, incidents, settings, displayModal }) => {
  const chartRef = useRef();
  const sortedUsers = users.map(u => u.firstName + ' ' + u.lastName).sort();
  const incidentsByUser = {};
  sortedUsers.forEach((user, i) => {
    incidentsByUser[user] = [];
    for(const incidentType of Object.keys(INCIDENT_TYPES)) {
      incidentsByUser[user][INCIDENT_TYPES[incidentType]] = 0;
    }
  });
  for(const incident of incidents) {
    if(incidentSettingEnabled(settings, incident.incidentType.name)) {
      incidentsByUser[incident.relatedMessage.user.firstName + ' ' + incident.relatedMessage.user.lastName][incident.incidentType.name]++;
    }
  }

  const dataValues = sortedUsers.map(u => sum(Object.values(incidentsByUser[u])));

  const data = {
    labels: sortedUsers,
    datasets: [{
      label: 'Risk',
      backgroundColor: '#AB0000',
      borderColor: '#AB0000',
      datalabels: {
        color: '#000000'
      },
      pointRadius: sortedUsers.map(u => sum(Object.values(incidentsByUser[u])) ? 15 : 0),
      pointHoverRadius: sortedUsers.map(u => sum(Object.values(incidentsByUser[u])) ? 15 : 0),
      pointHitRadius: sortedUsers.map(u => sum(Object.values(incidentsByUser[u])) ? 15 : 0),
      pointBackgroundColor: '#E7D9D9',
      pointHoverBackgroundColor: '#AB0000',
      pointHitBackgroundColor: '#AB0000',
      pointBorderColor: '#E7D9D9',
      pointHoverBorderColor: '#AB0000',
      pointHitBorderColor: '#AB0000',
      data: dataValues
    }]
  };

  const options = {
    hover: {
      onHover: e => {
        if(chartRef.current.chartInstance.getElementAtEvent(e).length) {
          e.target.style.cursor = 'pointer';
          chartRef.current.chartInstance.getElementAtEvent(e)[0].$datalabels[0]._model.color = '#FFFFFF';
        } else {
          e.target.style.cursor = 'default';
        }
      }
    },
    legend: {
      display: false
    },
    scale: {
      // Since a new maximum is set below, remove the tick associated with that maximum since it doesn't fit
      // with the step size of the radar (e.g. 5, 10, 15, 20, 25, 26.25)
      afterTickToLabelConversion: scale => {
        scale.ticks[scale.ticks.length - 1] = null;
        scale.ticksAsNumbers[scale.ticksAsNumbers.length - 1] = null;
      },
      angleLines: {
        display: false
      },
      pointLabels: {
        fontStyle: 'bold'
      },
      ticks: {
        backdropColor: 'rgba(219, 226, 231, 0.1)',
        beginAtZero: true,
        // make the maximum a little larger than the data's max so that the labels aren't covered by the data point
        max: max(dataValues) > 0 ? max(dataValues)*1.05 : 1.1
      }
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => 'Total: ' + tooltipItem[0].label,
        label: (tooltipItem, data) => {
          const user = data.labels[tooltipItem.index];
          return Object.keys(incidentsByUser[user]).reduce((acc, type) => {
            if(incidentsByUser[user][type] > 0) {
              acc.push(INCIDENT_TYPE_LABELS[type] + ': ' + incidentsByUser[user][type]);
            }
            return acc;
          }, []);
        }
      }
    }
  };

  const onClickElement = element => {
    const user = element[0] ? data.labels[element[0]._index] : '';
    if(!user) {
      return;
    }
    const incidents = Object.keys(INCIDENT_TYPES).filter(type => incidentSettingEnabled(settings, INCIDENT_TYPES[type])).map(type => INCIDENT_TYPES[type]);
    const userData = {
      labels: incidents.map(i => ''),
      datasets: [{
        label: 'Risk',
        backgroundColor: '#AB0000',
        borderColor: '#AB0000',
        datalabels: {
          color: '#FFFFFF'
        },
        pointBackgroundColor: incidents.map(type => INCIDENT_TYPE_COLORS[type]),
        pointBorderColor: incidents.map(type => INCIDENT_TYPE_COLORS[type]),
        pointRadius: 15,
        pointHoverRadius: 15,
        data: incidents.map(type => incidentsByUser[user][type])
      }]
    };
    const userOptions = {
      legend: {
        display: false
      },
      scale: {
        angleLines: {
          display: false
        },
        ticks: {
          beginAtZero: true,
          display: false
        }
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => '',
          label: (tooltipItem, data) => {
            const incidentType = incidents[tooltipItem.index];
            return INCIDENT_TYPE_LABELS[incidentType] + ': ' + incidentsByUser[user][incidentType];
          }
        }
      }
    };
    displayModal(
      <>
        <div className="incident-modal-title">
          <span className="incident-modal-title-name">{user}&apos;s</span> risks by type
        </div>
        <div className="ml-3 incident-modal-email">
          <FontAwesomeIcon icon={faEnvelope} />
          <span className="ml-2">{users.find(u => u.firstName + ' ' + u.lastName === user).email}</span>
        </div>
      </>,
      <>
        <div className="row no-gutters">
          <div className="col col-7 col-lg-8">
            <Radar height={800} width={800} data={userData} options={userOptions} />
          </div>
          <div className="col col-5 col-lg-4 mt-3">
            {Object.keys(INCIDENT_TYPES).map(incidentType => {
              if(!incidentSettingEnabled(settings, incidentType)) {
                return null;
              }

              const incident = INCIDENT_TYPES[incidentType];
              return (
                <div key={`${incident}-modal-legend-label`}>
                  <div className="d-inline-flex align-items-center">
                    <span className="legend-box mr-2 align-middle" style={{backgroundColor: INCIDENT_TYPE_COLORS[incident]}} />
                    <span className="legend-label align-middle">{INCIDENT_TYPE_LABELS[incident]}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col col-12 text-right total-risks">
            <span className="align-middle">Total Flagged Risks</span>
            <span className="total-risk-count align-middle mx-2">{sum(Object.values(incidentsByUser[user]))}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <Radar
      ref={r => chartRef.current = r}
      data={data}
      options={options}
      getElementAtEvent={onClickElement}
    />
  );
};

export default withOrgUsers(withModal(UserRiskRadarChart), 'guard_enabled');
