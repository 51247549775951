import moment from 'moment-timezone';

import { normalizeData } from 'src/common/csvHelpers';


// data - array of arrays/rows containing the table data
// sortColumnIndex - index of the column to sort by
// sortColumnDataType - what type of data is being sorted
// sortDirection - 'ASC' or 'DESC'
export const sortData = (data, sortColumnIndex, sortColumnDataType, sortDirection) => {
  if(sortColumnIndex >= 0 && sortDirection) {
    const sortedData = [];
    for(const rowData of data) {
      const currRow = [];
      for(const dataPoint of rowData) {
        currRow.push(normalizeData(dataPoint));
      }
      sortedData.push(currRow);
    }
    sortedData.sort((a, b) => {
      let normalizedA = a[sortColumnIndex];
      let normalizedB = b[sortColumnIndex];
      if(sortColumnDataType === 'MONEY') {
        // remove dollar sign, commas, and decimal point, then sort as an integer
        normalizedA = normalizedA ? parseInt(normalizedA.replace(/\$|,|\./g, ''), 10) : 0;
        normalizedB = normalizedB ? parseInt(normalizedB.replace(/\$|,|\./g, ''), 10) : 0;
      }
      // put empty values after sorted, non-empty values
      if(normalizedA === normalizedB) {
        return 0;
      } else if(normalizedA === null || normalizedA === '') {
        return 1;
      } else if(normalizedB === null || normalizedB === '') {
        return -1;
      } else if(
        (sortDirection === 'ASC' && normalizedA < normalizedB)
        || (sortDirection === 'DESC' && normalizedB < normalizedA)
      ) {
        return -1;
      } else {
        return 1;
      }
    });
    return sortedData;
  } else {
    return data;
  }
};

// data - array of arrays/rows containing the table data
// reportFilters - object with keys that represent a column index and values which are an array of values to filter on
export const filterData = (data, reportFilters) => {
  if(data && reportFilters) {
    const filteredData = [];

    // Check if each row should be included
    for(const rowData of data) {
      let keepRow = true;

      // Each filter may contain multiple values. If a filter has values,
      // only keep rows that have a datapoint within that set of values.
      const filterColumnIndexes = Object.keys(reportFilters);
      for(const columnIndex of filterColumnIndexes) {
        const activeFilters = reportFilters[columnIndex];
        if(activeFilters && activeFilters.length) {
          const activeFilterValues = activeFilters.map(filter => filter.value);
          keepRow = activeFilterValues.includes(rowData[columnIndex]);
        }
        if(!keepRow) {
          // no need to check the other filters
          break;
        }
      }

      if(keepRow) {
        filteredData.push(rowData);
      }
    }

    return filteredData;
  } else {
    return data;
  }
};

export const AGGREGATION_TYPE = {
  SUM: 'SUM',
  COUNT: 'COUNT'
};

// Combine data in a column to produce a resulting value
export const aggregateColumn = (tableData, columnIndex, aggregateType) => {
  let aggValue = 0;
  const numRows = tableData.length;
  for(let i = 0; i < numRows; i++) {
    const row = tableData[i];
    if(aggregateType === AGGREGATION_TYPE.SUM) {
      const number = Number(row[columnIndex]);
      if(isNaN(number)) {
        return NaN;
      }
      aggValue += number;
    } else if(aggregateType === AGGREGATION_TYPE.COUNT) {
      if(row[columnIndex] !== null && row[columnIndex] !== '') {
        aggValue += 1;
      }
    }
  }
  // return integers as is, round floats
  return aggValue % 1 === 0 ? aggValue : aggValue.toFixed(2);
};

export const formatDataByType = (dataPoint, dataType) => {
  switch(dataType) {
    case 'DATETIME':
      return moment(dataPoint).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss z')
    default:
      return dataPoint;
  }
};
