import React from 'react';

import 'src/guard/styles/guard-dashboard.scss';


const PageTitle = () => (
  <>
      <img className="guard-logo" alt="Guard Logo" src="https://s3.amazonaws.com/gravyty/gravyty_guard/guard-shield.png" />
      <span className="admin-title-first guard-title">Raise</span>
      &nbsp;
      <span className="admin-title-remaining guard-title">GUARD</span>
  </>
);

export default PageTitle;
