import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import 'src/report_dashboard/styles/bar-graph-report.scss';

import { getDefaultReportColors } from 'src/common/colorHelpers';
import { buildCSVFromBarGraph } from 'src/common/csvHelpers';
import { commonChartOptions } from 'src/report_dashboard/reportHelpers';
import { useDownloadReady } from 'src/report_dashboard/useDownloadReady';
import NoReportData from 'src/report_dashboard/NoReportData';


const removeNullDatasets = barGroups => {
  return barGroups.reduce((acc, bar) => {
    if(bar.values.findIndex(v => v !== null) !== -1) {
      acc.push(bar);
    }
    return acc;
  }, []);
};

const BarGraphReport = ({ labels, barGroups, downloadData, onDownloadReady }) => {
  useDownloadReady(downloadData, {labels, barGroups}, buildCSVFromBarGraph, onDownloadReady);
  const [stacked, setStacked] = useState(false);
  const onStackButtonClick = ev => setStacked(!stacked);

  const dataTotals = useMemo(() => {
    let sums = Array(barGroups[0].values.length).fill(0);
    barGroups.forEach(group => {
      sums.forEach((elem, index) => {
        sums[index] = elem + group.values[index]
      })
    });
    return sums;
  }, [barGroups]);

  const chartRef = useRef();
  const barGraphOptions = useMemo(() => {
    const linebreak = !stacked ? '\n' : ' ';
    const commonOpts = commonChartOptions(barGroups.length > 1, chartRef);
    commonOpts.scales = {
      xAxes: [{ stacked }],
      yAxes: [{ stacked }]
    };
    if (barGroups && barGroups[0].label === 'Not Contacted') {
      commonOpts.plugins.datalabels = {
        ...commonOpts.plugins.datalabels,
        textAlign: 'center',
        formatter: (value, context) => {
          return `${context.dataset.data[context.dataIndex]}${linebreak}(${(100*context.dataset.data[context.dataIndex]/dataTotals[context.dataIndex]).toFixed(0)}%)`;
        },
      };
      commonOpts.tooltips = {
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex].label;
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return `${label}: ${value} (${(100*value/dataTotals[tooltipItem.index]).toFixed(0)}%)`;
          }
        }
      };
    };
    return commonOpts;
  }, [barGroups, chartRef, stacked, dataTotals]);

  return (
    <div className="bar-graph report-results">
      {barGroups.length && barGroups.find(b => b.values.find(v => Number(v) !== 0)) ?
        <>
          {barGroups.length > 1
            ? <button type="button" className="btn btn-primary btn-sm" onClick={onStackButtonClick}>{stacked ? 'Unstack' : 'Stack'}</button>
            : null
          }
          <Bar
            ref={r => chartRef.current = r}
            data={{
              datasets: removeNullDatasets(barGroups).map((bar, i) => ({
                  label: bar.label,
                  data: bar.values,
                  backgroundColor: getDefaultReportColors(barGroups.length)[i],
                  datalabels: {
                    // Hide labels for bars with a zero value
                    display: bar.values.map(value => value > 0),
                  },
                  // Disable datasets with all zero values. They
                  // can be re-enabled by clicking on them in the legend,
                  // which is only displayed for grpahs with multiple bars.
                  hidden: barGroups.length > 1 && bar.values.reduce((acc, value) => acc &= Number(value) === 0, true)
              })),
              labels: labels
            }}
            options={barGraphOptions} />
        </>
        : <NoReportData />
      }
    </div>
  );
};

BarGraphReport.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  barGroups: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.number)
  })).isRequired,
  downloadData: PropTypes.bool.isRequired,
  onDownloadReady: PropTypes.func.isRequired
};

export default BarGraphReport;
