import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import 'src/common/styles/circle-buttons.scss';


const CircleButtons = ({ options, multiSelect, required, defaultValues, isRow, onClickButton }) => {
  const [ selectedButtons, setSelectedButtons ] = useState(
    defaultValues && defaultValues.length
      ? defaultValues.reduce((acc, v) => { acc[v] = true; return acc; }, {})
      : {}
  );

  const toggleButton = button => {

    setSelectedButtons(prevButtons => {
      const newButtons = {...prevButtons};
      if(button in prevButtons) {
        // don't disable the currently selected button if a selection is required
        if(!prevButtons[button] || !required || Object.keys(prevButtons).filter(b => prevButtons[b]).length > 1) {
          newButtons[button] = !prevButtons[button];
        }
      } else {
        newButtons[button] = true;
      }
      if(!multiSelect) {
        Object.keys(prevButtons).forEach(key => key !== button ? newButtons[key] = false : null);
      }
      return newButtons;
    });
    if(onClickButton) {
      onClickButton(button);
    }
  };

  return (
    <div className={classnames('circle-buttons', isRow && 'row')}>
      {options.map(option => (
        <div key={option.value} className={classnames(isRow && 'col')}>
          <div
            className={classnames('button', Object.keys(selectedButtons).reduce((acc, button) => { if(selectedButtons[button]) acc.push(button); return acc; }, []).includes(option.value) ? 'selected' : null)}
            onClick={ev => toggleButton(option.value) }>
            {option.label}
          </div>
        </div>
      ))}
    </div>
  );
}

CircleButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.string
  })),
  multiSelect: PropTypes.bool,
  required: PropTypes.bool,
  defaultValues: PropTypes.arrayOf(PropTypes.string),
  isRow: PropTypes.bool,
  onClickButton: PropTypes.func
};

CircleButtons.defaultProps = {
  isRow: true
};

export default CircleButtons;
