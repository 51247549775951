import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faChartPie } from '@fortawesome/free-solid-svg-icons'

import 'src/report_dashboard/styles/table-report.scss';

import { normalizeData, buildCSVFromTable } from 'src/common/csvHelpers';
import HideableSelect from 'src/common/HideableSelect';
import { addKeyValueToObjectState, removeKeyFromObjectState } from 'src/common/stateHelpers';
import { usePrevious } from 'src/common/usePrevious';
import NoReportData from 'src/report_dashboard/NoReportData';
import DynamicPieChartReport from 'src/report_dashboard/DynamicPieChartReport';
import { sortData, filterData, AGGREGATION_TYPE, aggregateColumn, formatDataByType } from 'src/report_dashboard/tableHelpers';
import { useDownloadReady } from 'src/report_dashboard/useDownloadReady';


const TableReport = ({ reportData, downloadTable, onDownloadReady, onToggleReport }) => {
  const [sortColumnIndex, setSortColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState('');
  const [reportFilters, setReportFilters] = useState({});
  const [aggregations, setAggregations] = useState({});
  const [aggregateValues, setAggregateValues] = useState({});

  const filteredReportDataRows = useMemo(() => reportData ? filterData(reportData.rows, reportFilters) : [], [reportData, reportFilters]);

  useDownloadReady(
    downloadTable,
    {
      columns: reportData.columns,
      rows: sortData(filteredReportDataRows, sortColumnIndex, sortColumnIndex >= 0 ? reportData.columns[sortColumnIndex].dataType : '', sortDirection)
    },
    buildCSVFromTable,
    onDownloadReady
  );

  const onClickColumn = (colIndex) => () => {
    if(colIndex === sortColumnIndex) {
      if(sortDirection === 'ASC') {
        setSortDirection('DESC');
      } else if(sortDirection === 'DESC') {
        setSortDirection('');
      } else {
        setSortDirection('ASC');
      }
    } else {
      setSortDirection('ASC');
      setSortColumnIndex(colIndex);
    }
  };

  const onFilterChange = columnIndex => selectedData => {
    setReportFilters(addKeyValueToObjectState(columnIndex, selectedData));
  };

  const onAggregateChange = columnIndex => selectedData => {
    if(selectedData) {
      setAggregateValues(addKeyValueToObjectState(columnIndex, aggregateColumn(filteredReportDataRows, columnIndex, selectedData.value).toString()));
      setAggregations(addKeyValueToObjectState(columnIndex, selectedData.value));
    } else {
      // The aggregation selection was cleared
      setAggregateValues(removeKeyFromObjectState(columnIndex.toString()));
      setAggregations(removeKeyFromObjectState(columnIndex.toString()));
    }
  };

  const prevFilteredReportDataRowsCount = usePrevious(filteredReportDataRows.length)
  useEffect(() => {
    // If the filtered data has changed, update any aggregations
    if(prevFilteredReportDataRowsCount !== filteredReportDataRows.length) {
      for(let col of Object.keys(aggregateValues)) {
        onAggregateChange(col)({ value: aggregations[col] });
      }
    }
  });

  const onAddChart = colIndex => clickEvent => {
    onToggleReport(reportData.columns[colIndex].name, <DynamicPieChartReport colIndex={colIndex} data={{ columns: reportData.columns, rows: filteredReportDataRows}} />);
    clickEvent.stopPropagation();
  };

  if(!reportData) return null;
  return (
    <div className="data-table report-results">
      {reportData.rows.length ?
        <table>
          <tbody>
            <tr>
              {reportData.columns && reportData.columns.map((col, colIndex) => {
                const selectOptionsMap = new Map();
                // If this column has a filter, show all options. If there is no filter on this column, but a filter on other columns, then
                // filter the options to only show the filtered data.
                const possibleOptions = reportFilters && reportFilters[colIndex] && reportFilters[colIndex].length ? reportData.rows : filteredReportDataRows;
                for(const row of possibleOptions) {
                  const cellValue = row[colIndex];
                  selectOptionsMap.set(cellValue, { value: cellValue, label: cellValue || '--BLANK--'})
                }
                const selectOptionsArray = Array.from(selectOptionsMap.values());
                selectOptionsArray.sort((a,b) => normalizeData(a.value) < normalizeData(b.value) ? -1 : 1);

                return (
                  <th align="left" key={col.name} onClick={onClickColumn(colIndex)}>
                    <div className="column-title-row row no-gutters">
                      <div className="column-title col-10">
                        {col.name}
                        {
                          sortDirection && sortColumnIndex === colIndex ?
                            sortDirection === 'ASC' ?
                              <FontAwesomeIcon icon={faSortUp} />
                              : sortDirection === 'DESC' ? <FontAwesomeIcon icon={faSortDown} />
                              : null
                            : null
                        }
                      </div>
                      <div className="pie-chart-button-wrapper col-2 text-right">
                        <button className="pie-chart-button" onClick={onAddChart(colIndex)}><FontAwesomeIcon icon={faChartPie} /></button>
                      </div>
                    </div>
                    <HideableSelect
                      name="Filter"
                      options={selectOptionsArray}
                      isMulti={true}
                      onSelect={onFilterChange(colIndex)} />
                    <HideableSelect
                      name="Aggregation"
                      options={Object.keys(AGGREGATION_TYPE).map(type => ({ label: type.toLowerCase(), value: AGGREGATION_TYPE[type] }))}
                      onSelect={onAggregateChange(colIndex)}
                      footerValue={aggregateValues[colIndex]} />
                  </th>
                );
              })}
            </tr>
            {reportData.rows && sortData(filteredReportDataRows, sortColumnIndex, sortColumnIndex >= 0 ? reportData.columns[sortColumnIndex].dataType : '', sortDirection).map((row, rowNum) =>
              <tr key={`report-row-${rowNum}`}>
                {row.map((cell, colNum) => <td key={`report-cell-${rowNum}-${colNum}`}>{formatDataByType(cell, reportData.columns[colNum].dataType)}</td>)}
              </tr>
            )}
          </tbody>
        </table>
        : <NoReportData />
      }
    </div>
  );
};

TableReport.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array
  }),
  downloadTable: PropTypes.bool,
  onDownloadReady: PropTypes.func,
  onToggleReport: PropTypes.func
};

export default TableReport;
