// Only call onBlur if next element with focus is not a child element
export const onContainerBlur = (blurEvent, onBlur) => {
  const currentTarget = blurEvent.currentTarget;
  // setTimeout will run after the event loop
  setTimeout(_ => {
    // check if the current active element is a child of the element that triggered the onBlur
    if (!currentTarget.contains(document.activeElement)) {
      onBlur();
    }
  }, 0);
};
