// Convert a UTC date to a date in the user's timezone
export const dateStringToLocalTime = d => {
  if(!d) return null;
  const localTimezoneOffset = new Date().getTimezoneOffset();
  const localDate = new Date(d);
  localDate.setMinutes(localDate.getMinutes() + localTimezoneOffset);
  return localDate;
};

// Convert local timestamp to UTC date
export const localTimeToDateString = dateTime => {
  const localTimezoneOffset = new Date().getTimezoneOffset();
  const newDate = new Date(dateTime.getTime());
  newDate.setMinutes(newDate.getMinutes() - localTimezoneOffset);
  return newDate.toISOString().split('T')[0];
};

export const dateToString = d => {
  return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
};
