import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import QueryWrapper from 'src/common/QueryWrapper';

import { loader } from 'graphql.macro';
const GET_CURRENT_USER = loader('../graphql/user/get_current_user.gql');


const withCurrentUser = WrappedComponent => {
  const Component = props => {
    return (
      <QueryWrapper queryResult={useQuery(GET_CURRENT_USER)}>
        {data => <WrappedComponent currentUser={data.currentUser} {...props} />}
      </QueryWrapper>
    );
  }

  return Component;
}

export default withCurrentUser;
