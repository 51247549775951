import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { Popover, OverlayTrigger } from 'react-bootstrap'

import 'src/fundraiser/setting/styles/setting.scss';


const Setting = ({title, id, description, hasUnsavedValue, children}) => {
  const popover = (
    <Popover id={id}>
      <Popover.Title as="h4">{title}</Popover.Title>
      <Popover.Content>
        {description}
      </Popover.Content>
    </Popover>
  );

  return (
    <div className={classnames("setting row mx-0", hasUnsavedValue && 'setting-changed')}>
      <OverlayTrigger trigger="click" placement="top" rootClose overlay={popover}>
        <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
      </OverlayTrigger>
      <p className="setting-title align-self-center">{title}</p>
      {children}
    </div>
  )
}

Setting.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasUnsavedValue: PropTypes.bool
};

export default Setting;
