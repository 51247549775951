import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { uniq } from 'lodash';

import Loading from 'src/common/Loading';

import 'src/fundraiser/setting/styles/settings-loading.scss';


// This is similar to QueryWrapper, and at some point we may want to merge the two components
const SettingsLoading =({ query, variables, children }) => {
  const [ isSaving, setIsSaving ] = useState(true);
  // notifyOnNetworkStatusChange ensures onCompleted gets called when using refetch
  const queryResult = useQuery(query, { variables: variables, notifyOnNetworkStatusChange: true, onCompleted: _ => setIsSaving(false)});

  return (
    queryResult.error
      ? queryResult.error.graphQLErrors && queryResult.error.graphQLErrors.length
        // if there are multiple graphql errors they get concatenated in queryResult.error.message, so show each of those instead
        ? uniq(queryResult.error.graphQLErrors.map(e => e.message)).map((e, i) => <p key={`error-${i}`}>Error: {e}</p>)
        : <p>Error: {queryResult.error.message}</p>
      : <div className="settings-loading">
          {queryResult.data && children(queryResult.data, queryResult.refetch, setIsSaving)}
          {(queryResult.loading || isSaving) && <Loading largeSpinner={true} />}
        </div>
  );
};

export default SettingsLoading;
