import { useEffect } from 'react';

import { usePrevious } from 'src/common/usePrevious';


// Custom Hook for using the onDownloadReady callback of the Report component.
// Use previous downloadData state to prevent multiple consecutive downloads.
// Pass csv data to onDownloadReady callback.
export const useDownloadReady = (downloadData, data, buildCSVFromData, onDownloadReady) => {
  const prevDownloadData = usePrevious(downloadData);
  useEffect(() => {
    if(!prevDownloadData && downloadData && data) {
      onDownloadReady(buildCSVFromData(data));
    }
  }, [prevDownloadData, downloadData, data, buildCSVFromData, onDownloadReady]);
};
