import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import 'src/guarantee_calculator/styles/followup.scss';

import { onContainerBlur } from 'src/common/componentHelpers';
import { calculateWeeklyTotal } from 'src/guarantee_calculator/calculatorHelpers';
import { useEditMode } from 'src/common/useEditMode';


// id - a unique id within each week
// count - how many followups to perform
// countPeriod - how often to perform `count` follow-ups
// type - how the follow-ups will be made
// weeksOffset - when the follow-ups will be made relative to the week that
//               contains this follow-up
// actionNumber - which number follow-up is this for the FDs within the week
//                (e.g. 1 => the first follow-up, 2 => the second follow-up)
export class FollowUpModel {
  constructor(id, count, countPeriod, type, weeksOffset, actionNumber) {
    this.id = id;
    this.count = count;
    this.countPeriod = countPeriod;
    this.type = type;
    this.weeksOffset = weeksOffset;
    this.actionNumber = actionNumber;
  }
  calcWeeklyCount() {
    return calculateWeeklyTotal(this.count, this.countPeriod);
  }
};

const followUpReducer = (followUp, action) => {
  switch (action.type) {
    case 'count': return new FollowUpModel(followUp.id, action.payload, followUp.countPeriod, followUp.type, followUp.weeksOffset, followUp.actionNumber);
    case 'countPeriod': return new FollowUpModel(followUp.id, followUp.count, action.payload, followUp.type, followUp.weeksOffset, followUp.actionNumber);
    case 'type': return new FollowUpModel(followUp.id, followUp.count, followUp.countPeriod, action.payload, followUp.weeksOffset, followUp.actionNumber);
    case 'weeksOffset': return new FollowUpModel(followUp.id, followUp.count, followUp.countPeriod, followUp.type, action.payload, followUp.actionNumber);
    default: throw new Error();
  }
};

const FollowUp = ({ initialFollowUp, onDelete, onChange }) => {
  const [followUp, dispatch] = useReducer(followUpReducer, initialFollowUp);
  const [mode, setMode, editDivRef] = useEditMode(false);

  useEffect(_ => onChange(followUp), [followUp, onChange]);
  const onFollowUpBlur = _ => followUp.count && followUp.weeksOffset && setMode('READ');

  return mode === 'EDIT' ?
    <div ref={editDivRef} className="follow-up" tabIndex={0} onBlur={ev => onContainerBlur(ev, onFollowUpBlur)}>
      <FontAwesomeIcon className="follow-up-remove" icon={faTimes} size="sm" onClick={_ => onDelete(followUp.id)} />
      <input
        className="form-control"
        autoComplete="off"
        type="number"
        value={followUp.count || 0}
        onChange={ev => dispatch({ type: 'count', payload: parseInt(ev.target.value, 10) })} />
      <span>per</span>
      <select className="form-control" value={followUp.countPeriod} onChange={ev => dispatch({ type: 'countPeriod', payload: ev.target.value })}>
        <option value="DAY">day</option>
        <option value="WEEK">week</option>
      </select>
      <span>by</span>
      <select className="form-control" value={followUp.type} onChange={ev => dispatch({ type: 'type', payload: ev.target.value })}>
        <option value="Email">Email</option>
        <option value="Call">Call</option>
        <option value="Letter">Letter</option>
        <option value="Text">Text</option>
      </select>
      <span>in</span>
      <input
        className="form-control"
        autoComplete="off"
        type="number"
        value={followUp.weeksOffset || 0}
        onChange={ev => dispatch({type: 'weeksOffset', payload: parseInt(ev.target.value, 10) })} />
      <span>weeks</span>
    </div> :
    <div className="follow-up-read-only" onClick={_ => setMode('EDIT')}>
      <ul>
        <li className="read-only-mode">
          {`${followUp.type === 'Call' ? 'Make' : 'Send'} ${followUp.count} follow-up ${followUp.type}s per ${followUp.countPeriod.toLowerCase()} in ${followUp.weeksOffset} week${followUp.weeksOffset > 1 ? 's' : ''}`}
        </li>
      </ul>
    </div>;
}

FollowUp.propTypes = {
  initialFollowUp: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FollowUp;
