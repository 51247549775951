import { faEyeSlash, faFile, faFolder, faIdCard } from '@fortawesome/free-regular-svg-icons'
import { faBullseye, faLink } from '@fortawesome/free-solid-svg-icons'


export const MAX_FILE_SIZE = 50;

export const INCIDENT_CHART_PERIOD_OPTIONS = [
  { label: 'WK', value: 'week' },
  { label: 'MO', value: 'month' }
];

export const INCIDENT_TYPES = {
  OUTBOUND_BODY_ANON_DONOR_MENTION: 'OUTBOUND_BODY_ANON_DONOR_MENTION',
  INBOUND_HEADER_FROM_REPLYTO_MISMATCH: 'INBOUND_HEADER_FROM_REPLYTO_MISMATCH',
  OUTBOUND_ATTACHMENT_FILESIZE: 'OUTBOUND_ATTACHMENT_FILESIZE',
  OUTBOUND_ATTACHMENT_FILETYPE: 'OUTBOUND_ATTACHMENT_FILETYPE',
  OUTBOUND_BODY_SENSITIVE: 'OUTBOUND_BODY_SENSITIVE',
  INBOUND_BODY_DECEPTIVE_LINK: 'INBOUND_BODY_DECEPTIVE_LINK'
};

export const INCIDENT_TYPE_SETTINGS = {
  OUTBOUND_BODY_ANON_DONOR_MENTION: ['sent_anonymous_donor_info'],
  INBOUND_HEADER_FROM_REPLYTO_MISMATCH: ['received_from_replyto_mismatch'],
  OUTBOUND_ATTACHMENT_FILESIZE: ['attachment_size_threshold'],
  OUTBOUND_ATTACHMENT_FILETYPE: ['attachment_type_check_archive', 'attachment_type_check_spreadsheet'],
  OUTBOUND_BODY_SENSITIVE: ['sent_sensitive_info_ach', 'sent_sensitive_info_ssn'],
  INBOUND_BODY_DECEPTIVE_LINK: ['received_deceptive_link']
};

export const CSV_FILE_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.apple.numbers',
  'application/vnd.google-apps.spreadsheet',
  'text/csv'
];

export const ZIP_FILE_TYPES = [
  'application/zip',
  'application/vnd.rar',
  'application/x-7z-compressed'
];

export const ACH_INFO_TYPE = 'Bank Routing and Account Numbers';

export const SSN_INFO_TYPE = 'Social Security Number';

export const INCIDENT_TYPE_COLORS = {
  [INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION]: '#A51D47',
  [INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH]: '#1C5D99',
  [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]: '#1E1E24',
  [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]: '#62BEC1',
  [INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]: '#FF818A',
  [INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK]: '#FF9000',
};

export const INCIDENT_TYPE_ICONS = {
  [INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION]: faIdCard,
  [INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH]: faBullseye,
  [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]: faFile,
  [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]: faFolder,
  [INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]: faEyeSlash,
  [INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK]: faLink,
};

export const INCIDENT_TYPE_LABELS = {
  [INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION]: 'Anonymous Donor',
  [INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH]: 'Spear Phishing Attempts',
  [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]: 'Large Attachments',
  [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]: 'File Types',
  [INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]: 'Sensitive Info',
  [INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK]: 'Suspicious Links',
};

export const INCIDENT_TYPE_TIPS = {
    [INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION]: 'Emails which display the name of an anonymous donor in plain text',
    [INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH]: 'A personalized, specific attempt through email to trick employees into divulging information',
    [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]: 'Files attached to your email that exceed your file size threshold',
    [INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]: 'Types of files attached to emails that may contain sensitive information',
    [INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]: 'Often called personal identification information (PII), is any information not meant to be public',
    [INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK]: 'Misleading links that, when clicked on, redirect to a different URL than what is displayed in the email body',
  };

export const INCIDENT_TYPE_ROUTES = {
  'anonymous-donor': INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION,
  'spearphishing-attempts': INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH,
  'large-attachments': INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE,
  'file-types': INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE,
  'sensitive-info': INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE,
  'suspicious-links': INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK,
};
