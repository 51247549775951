import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { IS_LOGGED_IN } from '../graphql/user/login.gql';

import QueryWrapper from 'src/common/QueryWrapper';


const withIsAuthenticated = WrappedComponent => {
  // Don't use apollo cache when fetching this. We want to know what the server thinks.
  const AuthenticationComponent = props => {
    return (
      <div className="page-container">
        <QueryWrapper queryResult={useQuery(IS_LOGGED_IN, { fetchPolicy: 'network-only' })}>
          {data => <WrappedComponent isAuthenticated={data.isAuthenticated} {...props} />}
        </QueryWrapper>
      </div>
    );
  }

  return AuthenticationComponent;
}

export default withIsAuthenticated;
