import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons';

import 'src/guard/styles/incident-panels.scss';

import { INCIDENT_TYPES, INCIDENT_TYPE_COLORS, INCIDENT_TYPE_ICONS, INCIDENT_TYPE_LABELS, INCIDENT_TYPE_TIPS } from 'src/guard/constants';
import { incidentSettingEnabled } from 'src/guard/settingsHelpers';
import IncidentTable from 'src/guard/IncidentTable';
import StatPanel from 'src/guard/StatPanel';


const IncidentPanels = ({ incidentCounts, previousCounts, incidentEmails, selectedPeriod, settings, isLoading }) => {
  const priorPeriodPercentages = {};
  const percentageClassnames = {}
  Object.keys(previousCounts).forEach(incidentType => {
    const prevValue = previousCounts[incidentType];
    const currValue = incidentCounts[incidentType];
    if(prevValue) {
      const change = Math.round(100 * (currValue - prevValue) / prevValue);
      priorPeriodPercentages[incidentType] = (change > 0 ? '+' : '') + change.toString() + '%';
      percentageClassnames[incidentType] = change > 0 ? 'decrease' : change < 0 ? 'increase' : 'equal'
    }
    else {
      priorPeriodPercentages[incidentType] = `0 last ${selectedPeriod}`;
      percentageClassnames[incidentType] = '';
    }
  });

  return (
    <>
      {Object.keys(INCIDENT_TYPES).map(incidentType => {
        if(!incidentSettingEnabled(settings, incidentType)) {
          return null;
        }

        const incident = INCIDENT_TYPES[incidentType];
        const incidentColor = INCIDENT_TYPE_COLORS[incident];
        return (
          <div className="incident-panel col" key={`${incident}-panel`}>
            <StatPanel
              title={INCIDENT_TYPE_LABELS[incident].toUpperCase()}
              value={incidentCounts[incident]}
              valueColor={incidentColor}
              subvalue={priorPeriodPercentages[incident]}
              subvalueClassname={percentageClassnames[incident]}
              dataTip={INCIDENT_TYPE_TIPS[incident]}
              dataTipId={`${incident}-tip`}
              isLoading={isLoading}
              modalIcon={faTable}
              modalTitle={
                <div>
                  <FontAwesomeIcon
                    icon={INCIDENT_TYPE_ICONS[incident]}
                    style={{ color: incidentColor }}
                    size="2x"
                  />
                  <div className="incident-panel-modal-title">
                    <div style={{ color: incidentColor }}>{INCIDENT_TYPE_LABELS[incident]}</div>
                    <div className="incident-panel-modal-subtitle">DETAILED VIEW</div>
                  </div>
                </div>
              }
              modalClassName="stat-panel-modal"
              modalContent={
                <>
                  <div className="row no-gutters">
                    <div className="col col-12">
                      <IncidentTable emails={incidentEmails[incident]} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-12 text-right total-incidents">
                      <span className="align-middle">Total emails flagged for {INCIDENT_TYPE_LABELS[incident]}</span>
                      <span className="total-incident-count align-middle mx-2" style={{ color: incidentColor }}>{incidentCounts[incident]}</span>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        );
      })}
    </>
  );
};

IncidentPanels.propTypes = {
  incidentCounts: PropTypes.object.isRequired,
  previousCounts: PropTypes.object.isRequired,
  incidentEmails: PropTypes.object.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

export default IncidentPanels;
