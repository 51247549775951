import React from 'react';

import Select from 'react-select';

import 'src/common/styles/custom-dropdown.scss';


const CustomDropdown = ({ options, onChange, isSearchable, name, value }) => {
  return (
    <Select 
        className="custom-dropdown"
        classNamePrefix="custom"
        options={options}
        onChange={onChange} 
        isSearchable={isSearchable}
        name={name}
        value={value}
    />
  )
};

export default CustomDropdown;
