import React, { useCallback } from 'react';

import EmailfieldSetting from 'src/fundraiser/setting/EmailfieldSetting';


const AssistantEmailsSetting = ({ assistantEmailAddresses, hasUnsavedValue, onAssistantEmailChange }) => {
  const addEmailAddress = useCallback(emailAddress => {
    const newList = [...assistantEmailAddresses];
    if(!newList.includes(emailAddress)) {
      newList.push(emailAddress);
    }
    onAssistantEmailChange(newList);
  }, [assistantEmailAddresses, onAssistantEmailChange]);

  const removeEmailAddress = useCallback(emailAddress => {
    let newList = [...assistantEmailAddresses];
    const removeIndex = newList.indexOf(emailAddress);
    if(removeIndex >= 0) {
      newList.splice(removeIndex, 1);
    }
    onAssistantEmailChange(newList);
  }, [assistantEmailAddresses, onAssistantEmailChange]);

  return (
    <EmailfieldSetting
      id="assistant-emails"
      title="CC Recipients"
      description="Activating this setting allows you to add email addresses in CC to receive a copy of your Raise email prompts, such as to copy an assistant.  The CC will only receive copies of the emails Raise sends to YOU, not the emails you send to your donors."
      emailAddresses={assistantEmailAddresses}
      hasUnsavedValue={hasUnsavedValue}
      onAdd={addEmailAddress}
      onRemove={removeEmailAddress}
    />
  );
};

export default AssistantEmailsSetting;
