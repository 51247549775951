import React from 'react';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import 'src/report_dashboard/styles/individual-donor-journey.scss';

import {
  getEventImage,
  getEventName,
  getEventSubtitle,
  getEventTime,
  sortEventsByDate
} from 'src/report_dashboard/donor_journey/donorEventUtils';


const IndividualDonorJourney = React.forwardRef(({ title, donorEvents }, ref) => {
  return (
    <div className="individual-donor-journey" ref={ref}>
      <div className="individual-donor-journey-title">{title}</div>
      <VerticalTimeline animate={false}>
        {sortEventsByDate(donorEvents).map((donorEvent, i) => {
          const image = getEventImage(sortEventsByDate(donorEvents), i);
          let icon = null;
          if(image instanceof Image) {
            icon = <img src={image.src} alt="" />
          }
          return (
            <VerticalTimelineElement
              key={`timeline-element-${i}`}
              className="vertical-timeline-element--work"
              icon={icon}
            >
              <h3 className="vertical-timeline-element-title">{getEventName(donorEvent)}</h3>
              <h4 className="vertical-timeline-element-subtitle">{getEventSubtitle(donorEvent)}</h4>
              <p>{getEventTime(donorEvent)}</p>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
});

export default IndividualDonorJourney;
