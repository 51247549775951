import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import 'src/guard/styles/guard-dashboard.scss';

import AdminPage from 'src/common/AdminPage';
import CircleButtons from 'src/common/CircleButtons';
import ModalButton from 'src/common/ModalButton';

import withGuardSettings from 'src/guard/withGuardSettings';
import { INCIDENT_CHART_PERIOD_OPTIONS, INCIDENT_TYPES, INCIDENT_TYPE_ROUTES } from 'src/guard/constants';
import HealthBar from 'src/guard/HealthBar';
import EmailCountPanel from 'src/guard/EmailCountPanel';
import IncidentGraphs from 'src/guard/IncidentGraphs';
import IncidentPanels from 'src/guard/IncidentPanels';
import PageTitle from 'src/guard/PageTitle';
import SettingsControls from 'src/guard/SettingsControls';
import { incidentSettingEnabled, incidentInfoFilter } from 'src/guard/settingsHelpers';

import { loader } from 'graphql.macro';
const GET_DASHBOARD_DATA = loader('../graphql/guard/get_dashboard_data.gql');


const GuardDashboard = ({ match, ...guardSettings }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('week');
  const [orgHealth, setOrgHealth] = useState(100);
  const [emailCounts, setEmailCounts] = useState({sent: 0, received: 0});
  const [incidentsThisPeriod, setIncidentsThisPeriod] = useState([]);
  const [incidentEmails, setIncidentEmails] = useState({});
  const [incidentCounts, setIncidentCounts] = useState({});
  const [incidentCountsPrevPeriod, setIncidentCountsPrevPeriod] = useState({});

  const todayText = moment().format("LL");
  const startTime = moment().subtract(1, selectedPeriod).add(1, 'day').startOf('day');
  const startTimeText = startTime.format("LL")
  const prevPeriodStartTime = startTime.clone().subtract(1, selectedPeriod);

  const onGetData = data => {
    let filteredIncidents = data.orgIncidents.filter(i =>
      incidentSettingEnabled(guardSettings, i.incidentType.name)
      && incidentInfoFilter(guardSettings, i)
    );

    // Remove duplicate incidents (i.e. incidents of a specific type
    // that occur more than once in the same email)
    const emailIDsByIncident = {};
    for(const incidentType of Object.keys(INCIDENT_TYPES)) {
      emailIDsByIncident[incidentType] = new Set();
    }
    filteredIncidents = filteredIncidents.filter(i => {
      const keep = !emailIDsByIncident[i.incidentType.name].has(i.relatedMessage.id);
      emailIDsByIncident[i.incidentType.name].add(i.relatedMessage.id);
      return keep;
    });

    const emailCounts = data.orgEmailCounts;
    const totalEmails = emailCounts.sent + emailCounts.received;
    const incidentsThisPeriod = filteredIncidents.filter(i =>
      moment(i.relatedMessage.messageTimestamp).isBetween(startTime, moment())
    );
    const incidentsPrevPeriod = filteredIncidents.filter(i =>
      moment(i.relatedMessage.messageTimestamp).isBetween(prevPeriodStartTime, startTime)
    );

    const incidentEmails = {}
    const incidentCounts = {};
    const incidentCountsPrevPeriod = {};
    for(const incidentType of Object.keys(INCIDENT_TYPES)) {
      incidentEmails[incidentType] = [];
      incidentCounts[incidentType] = 0;
      incidentCountsPrevPeriod[incidentType] = 0;
    }
    incidentsThisPeriod.forEach(incident => {
      incidentEmails[incident.incidentType.name].push(incident.relatedMessage);
      incidentCounts[incident.incidentType.name]++;
    });
    incidentsPrevPeriod.forEach(incident => incidentCountsPrevPeriod[incident.incidentType.name]++);

    // Calculate the Org's health as 100 minus the percentage of emails with an incident
    const uniqueIncidentEmailsThisPeriod = new Set(incidentsThisPeriod.map(incident => incident.relatedMessage.id));
    setOrgHealth(totalEmails ? 100 - 100 * uniqueIncidentEmailsThisPeriod.size / totalEmails : 100);
    setEmailCounts(emailCounts);
    setIncidentsThisPeriod(incidentsThisPeriod);
    setIncidentEmails(incidentEmails);
    setIncidentCounts(incidentCounts);
    setIncidentCountsPrevPeriod(incidentCountsPrevPeriod);
  };

  const { loading, refetch } = useQuery(
    GET_DASHBOARD_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: { start_date: startTime, prev_start_date: prevPeriodStartTime },
      onCompleted: onGetData
    }
  );

  return (
    <AdminPage titleComponent={<PageTitle />}>
      <div className="guard-dashboard">
        <p className="text-right date-range">{startTimeText} - {todayText}</p>
        <div className="row align-items-start mb-4">
          <div className="col-auto">
            <HealthBar value={Math.round(orgHealth)} healthy={orgHealth >= 90} />
          </div>
          <div className="col">
            <CircleButtons
              options={INCIDENT_CHART_PERIOD_OPTIONS}
              multiSelect={false}
              required={true}
              defaultValues={[selectedPeriod]}
              isRow={false}
              onClickButton={value => setSelectedPeriod(value)}
            />
          </div>
        </div>
        <div className="panel-row row">
          <EmailCountPanel
            emailCounts={emailCounts}
            isLoading={loading}
          />
          <IncidentPanels
            incidentCounts={incidentCounts}
            previousCounts={incidentCountsPrevPeriod}
            incidentEmails={incidentEmails}
            selectedPeriod={selectedPeriod}
            settings={guardSettings}
            isLoading={loading}
          />
        </div>
        <div className="row">
          <IncidentGraphs
            incidents={incidentsThisPeriod}
            startTime={startTime}
            settings={guardSettings}
            selectedIncidentType={INCIDENT_TYPE_ROUTES[match.params.incidentType]}
            isLoading={loading}
          />
        </div>
        <div className="row">
          <div className="col settings-button">
            <ModalButton
              label={<FontAwesomeIcon icon={faCog} size="2x" />}
              value="settings"
              isRow={false}
              title="Settings"
              bodyContent={<SettingsControls settings={guardSettings} refetchQuery={refetch} />}
              modalClassName="settings-modal"
            />
          </div>
        </div>
      </div>
    </AdminPage>
  );
}

export default withGuardSettings(GuardDashboard);
