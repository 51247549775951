import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';

import ModalButton from 'src/common/ModalButton';

import 'src/common/styles/feedback-button.scss';

import { loader } from 'graphql.macro';
const SEND_FEEDBACK_EMAIL = loader('../graphql/fundraiser/send_feedback_email.gql');


const FeedbackTextArea = ({ textAreaRef }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const updateText = ev => {
    setFeedbackText(ev.target.value);
  };

  return (
    <>
      <p>We welcome your feedback, information about problems you are experiencing, and feature requests. Please provide as much detail as possible.</p>
      <textarea className="form-control" rows="3" onChange={updateText} value={feedbackText} ref={textAreaRef} />
    </>
  );
};

const FeedbackModal = ({ displayToast }) => {
  const [ sendFeedbackEmail ] = useMutation(SEND_FEEDBACK_EMAIL, {
    onCompleted: () => displayToast(<span className="text-success">Feedback Email Sent!</span>, 'Thank you for your feedback', true),
    onError: () => displayToast(<span className="text-danger">Feedback Email Error</span>, 'Please contact your Customer Success Manager', false)
  });
  const [error, setError] = useState('');
  const textAreaRef = useRef();
  const submitFeedback = () => {
    if(textAreaRef.current.value) {
      setError('');
      sendFeedbackEmail({ variables: { email_text: textAreaRef.current.value, page_name: window.location.href }});
      return true;
    }
    else {
      setError('Please enter a message');
      return false;
    }
  };

  return (
    <div className="feedback-button">
      <ModalButton
        label={<FontAwesomeIcon icon={faCommentAlt} />}
        value="feedback"
        isRow={false}
        title="Send Feedback"
        bodyContent={<FeedbackTextArea textAreaRef={textAreaRef} />}
        showButton={true}
        modalError={error}
        modalClassName="feedback-modal"
        onButtonClick={submitFeedback}
        onCloseModal={() => setError('')}
      />
    </div>
  );
};

export default FeedbackModal;
