import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import withFundraiserSettings from 'src/fundraiser/setting/withFundraiserSettings';
import DropdownSetting from 'src/fundraiser/setting/DropdownSetting';
import AssistantEmailsSetting from 'src/fundraiser/setting/AssistantEmailsSetting';
import OutOfOfficeSetting, { compareDateRanges, dateRangeFromDates } from 'src/fundraiser/setting/OutOfOfficeSetting';
import ToggleSetting from 'src/fundraiser/setting/ToggleSetting';
import { useSaveSettings } from 'src/fundraiser/setting/useSaveSettings';


const FundraiserSettingsForm = ({ selectedPortfolioID, settingValues, refetchSettings, setIsSaving, onFormUpdate, isSaving, saveComplete, isCancelling, cancelComplete }) => {
  const [firstDraftEmailsValue, setFirstDraftEmailsValue] = useState(settingValues.first_draft_emails_setting.value);
  const [topDonorExcludeRecentActionsValue, setTopDonorExcludeRecentActionsValue] = useState(settingValues.top_donor_exclude_recent_actions_setting.value);
  const [topDonorEligibilityGiftValue, setTopDonorEligibilityGiftValue] = useState(settingValues.top_donor_eligibility_gift_setting.value);
  const [meetingTypesToSuggestValue, setMeetingTypesToSuggestValue] = useState(settingValues.meeting_types_to_suggest_setting.value);
  const [donorCyclingValue, setDonorCyclingValue] = useState(settingValues.donor_cycling_setting.value);
  const [outOfOfficeDates, setOutOfOfficeDates] = useState(settingValues.out_of_office_setting);
  const [assistantEmailAddresses, setAssistanteEmailAddresses] = useState(settingValues.assistant_emails_setting);

  useEffect(() => {
    setFirstDraftEmailsValue(settingValues.first_draft_emails_setting.value);
    setTopDonorExcludeRecentActionsValue(settingValues.top_donor_exclude_recent_actions_setting.value);
    setTopDonorEligibilityGiftValue(settingValues.top_donor_eligibility_gift_setting.value);
    setMeetingTypesToSuggestValue(settingValues.meeting_types_to_suggest_setting.value);
    setDonorCyclingValue(settingValues.donor_cycling_setting.value);
    setOutOfOfficeDates(settingValues.out_of_office_setting);
    setAssistanteEmailAddresses(settingValues.assistant_emails_setting);
  }, [
    settingValues.first_draft_emails_setting.value,
    settingValues.top_donor_exclude_recent_actions_setting.value,
    settingValues.top_donor_eligibility_gift_setting.value,
    settingValues.meeting_types_to_suggest_setting.value,
    settingValues.donor_cycling_setting.value,
    settingValues.out_of_office_setting,
    settingValues.assistant_emails_setting
  ]);

  const [formChangeCount, setFormChangeCount] = useState(0);
  useEffect(() => {
    onFormUpdate(formChangeCount);
  }, [onFormUpdate, formChangeCount]);

  useSaveSettings(
    setFormChangeCount, saveComplete, refetchSettings, setIsSaving, isSaving,
    selectedPortfolioID, firstDraftEmailsValue, topDonorExcludeRecentActionsValue,
    topDonorEligibilityGiftValue, meetingTypesToSuggestValue, donorCyclingValue,
    outOfOfficeDates, assistantEmailAddresses
  );

  useEffect(() => {
    if(isCancelling) {
      setFormChangeCount(0);
      cancelComplete();
      setFirstDraftEmailsValue(settingValues.first_draft_emails_setting.value);
      setTopDonorExcludeRecentActionsValue(settingValues.top_donor_exclude_recent_actions_setting.value);
      setTopDonorEligibilityGiftValue(settingValues.top_donor_eligibility_gift_setting.value);
      setMeetingTypesToSuggestValue(settingValues.meeting_types_to_suggest_setting.value);
      setDonorCyclingValue(settingValues.donor_cycling_setting.value);
      setOutOfOfficeDates(settingValues.out_of_office_setting);
      setAssistanteEmailAddresses(settingValues.assistant_emails_setting);
    }
  }, [
    isCancelling, setFormChangeCount, cancelComplete,
    settingValues.first_draft_emails_setting.value, settingValues.top_donor_exclude_recent_actions_setting.value,
    settingValues.top_donor_eligibility_gift_setting.value, settingValues.meeting_types_to_suggest_setting.value,
    settingValues.donor_cycling_setting.value, settingValues.out_of_office_setting, settingValues.assistant_emails_setting
  ]);

  useEffect(() => {
    setFormChangeCount(0);
  }, [setFormChangeCount, selectedPortfolioID]);

  const onSettingToggle = (unsavedValue, savedValue, setSetting) => () => {
    if(unsavedValue === savedValue) {
      setFormChangeCount(oldCount => oldCount + 1);
    }
    else {
      setFormChangeCount(oldCount => oldCount - 1);
    }
    setSetting(oldValue => oldValue === 1 ? 0 : 1);
  };

  const onSettingChange = (unsavedValue, savedValue, setSetting) => newValue => {
    // setting back to original value
    if(isEqual(newValue, savedValue) && !isEqual(unsavedValue, savedValue)) {
      setFormChangeCount(oldCount => oldCount - 1);
    }
    // setting to a new value after being set to the original value
    else if(!isEqual(newValue, savedValue) && isEqual(unsavedValue, savedValue)) {
      setFormChangeCount(oldCount => oldCount + 1);
    }
    setSetting(newValue);
  };

  const onOutOfOfficeChange = (newStartDate, newEndDate) => {
    const newDateRange = dateRangeFromDates({fromDate: newStartDate, toDate: newEndDate});
    const unsavedDateRange = dateRangeFromDates(outOfOfficeDates);
    const savedDateRange = dateRangeFromDates(settingValues.out_of_office_setting);
    if(compareDateRanges(newDateRange, savedDateRange) && !compareDateRanges(unsavedDateRange, savedDateRange)) {
      setFormChangeCount(oldCount => oldCount - 1);
    }
    else if(!compareDateRanges(newDateRange, savedDateRange) && compareDateRanges(unsavedDateRange, savedDateRange)) {
      setFormChangeCount(oldCount => oldCount + 1);
    }
    setOutOfOfficeDates({fromDate: newStartDate, toDate: newEndDate})
  };

  return (
    <div className="fundraiser-settings-form row mt-3">
      <div className="col-12 my-2">
        <OutOfOfficeSetting
          dateRange={dateRangeFromDates(outOfOfficeDates)}
          hasUnsavedValue={!compareDateRanges(dateRangeFromDates(outOfOfficeDates), dateRangeFromDates(settingValues.out_of_office_setting))}
          onDateChange={onOutOfOfficeChange}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownSetting
          id="first-draft-emails"
          title="Raise Email Types"
          description='All Emails will send daily First Draft and Stewardship prompts. First Drafts Only will deliver only proactive cultivation prompts daily. Stewardship Only will only deliver prompts to thank donors when they make a gift.'
          settingValue={firstDraftEmailsValue}
          settingOptions={[{key: 1, value: 'All Emails'}, {key: 2, value: 'First Drafts Only'}, {key: 3, value: 'Stewardship Only'}, {key: 0, value: 'No Emails'}]}
          hasUnsavedValue={settingValues.first_draft_emails_setting.value !== firstDraftEmailsValue}
          onSelect={onSettingChange(firstDraftEmailsValue, settingValues.first_draft_emails_setting.value, setFirstDraftEmailsValue)}
        />
      </div>
      <div className="col-12 my-2">
        <ToggleSetting
          id="exclude-recent-actions"
          title="Exclude Donors with Recent Actions"
          description='When this setting is activated, you will not receive a First Draft for a donor you have contacted in the last 30 days, either through Raise or recorded in your CRM. This does not impact Stewardship prompts.'
          settingValue={topDonorExcludeRecentActionsValue}
          hasUnsavedValue={settingValues.top_donor_exclude_recent_actions_setting.value !== topDonorExcludeRecentActionsValue}
          onToggle={onSettingToggle(topDonorExcludeRecentActionsValue, settingValues.top_donor_exclude_recent_actions_setting.value, setTopDonorExcludeRecentActionsValue)}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownSetting
          id="top-donor"
          title="First Drafts for Donors with Recent Gifts"
          description='"Exclude Givers Last 3 Months" will prevent Raise from sending you a First Draft for a donor who has given in the last 3 months. "Exclude Givers This FY" will prevent Raise from sending you a First Draft for anyone who has made a gift of $25+ this Fiscal Year. "Ignore Prior Giving" will not prevent Raise from sending you a First Draft based on their recent giving.'
          settingValue={topDonorEligibilityGiftValue}
          settingOptions={[{key: 0, value: 'Exclude Givers This FY'}, {key: 1, value: 'Exclude Givers Last 3 Months'}, {key: 2, value: 'Ignore Prior Giving'}]}
          hasUnsavedValue={settingValues.top_donor_eligibility_gift_setting.value !== topDonorEligibilityGiftValue}
          onSelect={onSettingChange(topDonorEligibilityGiftValue, settingValues.top_donor_eligibility_gift_setting.value, setTopDonorEligibilityGiftValue)}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownSetting
          id="meeting-types"
          title="Available Meeting Types in First Drafts"
          description='Selecting "Call Only" will ensure Raise only suggests calls with donors, rather than meetings. This setting is suggested for fundraisers that do not travel or meet with donors in person.'
          settingValue={meetingTypesToSuggestValue}
          settingOptions={[{key: 0, value: 'All Meeting Types'}, {key: 1, value: 'Call Only'}]}
          hasUnsavedValue={settingValues.meeting_types_to_suggest_setting.value !== meetingTypesToSuggestValue}
          onSelect={onSettingChange(meetingTypesToSuggestValue, settingValues.meeting_types_to_suggest_setting.value, setMeetingTypesToSuggestValue)}
        />
      </div>
      <div className="col-12 my-2">
        <ToggleSetting
          id="donor-cycling"
          title="Multiple First Drafts per Donor Each FY"
          description='Deactivating this setting will result in only one First Draft prompt per donor per fiscal year. After you have seen all donors in your portfolio in the fiscal year, you will stop receiving First Drafts.  This setting does NOT affect Stewardship.'
          settingValue={donorCyclingValue}
          hasUnsavedValue={settingValues.donor_cycling_setting.value !== donorCyclingValue}
          onToggle={onSettingToggle(donorCyclingValue, settingValues.donor_cycling_setting.value, setDonorCyclingValue)}
        />
      </div>
      <div className="col-12 my-2">
        <AssistantEmailsSetting
          assistantEmailAddresses={assistantEmailAddresses}
          assistantEmails={settingValues.assistant_emails_setting}
          hasUnsavedValue={!isEqual(assistantEmailAddresses, settingValues.assistant_emails_setting)}
          onAssistantEmailChange={onSettingChange(assistantEmailAddresses, settingValues.assistant_emails_setting, setAssistanteEmailAddresses)}
        />
      </div>
    </div>
  );
};

FundraiserSettingsForm.propTypes = {
  selectedPortfolioID: PropTypes.string, // from withFundraiserSettings
  settingValues: PropTypes.object, // from withFundraiserSettings
  setIsSaving: PropTypes.func, // from withFundraiserSettings
  onFormUpdate: PropTypes.func,
  isSaving: PropTypes.bool
};

export default withFundraiserSettings(FundraiserSettingsForm);
