import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { loader } from 'graphql.macro';
const SET_FUNDRAISER_SETTINGS = loader('src/graphql/fundraiser/set_fundraiser_settings.gql');


export const useSaveSettings = (
  setFormChangeCount, saveComplete, refetchSettings, setIsSaving, isSaving,
  selectedPortfolioID, firstDraftEmailsValue, topDonorExcludeRecentActionsValue,
  topDonorEligibilityGiftValue, meetingTypesToSuggestValue, donorCyclingValue,
  outOfOfficeDates, assistantEmailAddresses
) => {
  const [ setSettingMutation ] = useMutation(SET_FUNDRAISER_SETTINGS, {
    onCompleted: ({ setting }) => {
      setFormChangeCount(0);
      saveComplete();
      refetchSettings();
    }
  });

  useEffect(() => {
    setIsSaving(isSaving);
    if(isSaving) {
      setSettingMutation({
        variables: {
          portfolioId: selectedPortfolioID,
          firstDraftEmailsValue,
          topDonorExcludeRecentActionsValue,
          topDonorEligibilityGiftValue,
          meetingTypesToSuggestValue,
          donorCyclingValue,
          fromDate: outOfOfficeDates.fromDate,
          toDate: outOfOfficeDates.toDate,
          assistantEmailAddresses
        }
      });
    }
  }, [
    setIsSaving,
    isSaving,
    setSettingMutation,
    selectedPortfolioID,
    firstDraftEmailsValue,
    topDonorExcludeRecentActionsValue,
    topDonorEligibilityGiftValue,
    meetingTypesToSuggestValue,
    donorCyclingValue,
    outOfOfficeDates,
    assistantEmailAddresses
  ]);
};
