import birthdayBlue from './svgs/birthday-blue.svg';
import birthdayGray from './svgs/birthday-gray.svg';
import email from './svgs/email.svg';
import fdFeatherBlue from './svgs/fd-blue.svg';
import fdFeatherGray from './svgs/fd-gray.svg';
import giftFirstLarge from './svgs/gift-yellow-large.svg';
import giftFirstSmall from './svgs/gift-yellow-small.svg';
import giftLarge from './svgs/gift-large.svg';
import giftLargeIncrease from './svgs/gift-large-arrow.svg';
import giftSmall from './svgs/gift-small.svg';
import giftSmallIncrease from './svgs/gift-small-arrow.svg';
import phone from './svgs/phone.svg';
import stewardshipBlue from './svgs/stewardship-blue.svg';
import stewardshipGray from './svgs/stewardship-gray.svg';
import text from './svgs/text.svg';


export const birthdayImage = new Image(25, 25);
birthdayImage.src = birthdayGray;

export const birthdaySentImage = new Image(25, 25);
birthdaySentImage.src = birthdayBlue;

export const firstDraftImage = new Image(25, 25);
firstDraftImage.src = fdFeatherGray;

export const firstDraftSentImage = new Image(25, 25);
firstDraftSentImage.src = fdFeatherBlue;

export const giftFirstLargeImage = new Image(25, 25);
giftFirstLargeImage.src = giftFirstLarge;

export const giftFirstSmallImage = new Image(25, 25);
giftFirstSmallImage.src = giftFirstSmall;

export const giftLargeImage = new Image(25, 25);
giftLargeImage.src = giftLarge;

export const giftLargeIncreaseImage = new Image(25, 25);
giftLargeIncreaseImage.src = giftLargeIncrease;

export const giftSmallImage = new Image(25, 25);
giftSmallImage.src = giftSmall;

export const giftSmallIncreaseImage = new Image(25, 25);
giftSmallIncreaseImage.src = giftSmallIncrease;

export const phoneImage = new Image(25, 25);
phoneImage.src = phone;

export const letterImage = new Image(25, 25);
letterImage.src = email;

export const stewardshipImage = new Image(25, 25);
stewardshipImage.src = stewardshipGray;

export const stewardshipSentImage = new Image(25, 25);
stewardshipSentImage.src = stewardshipBlue;

export const textImage = new Image(25, 25);
textImage.src = text;
