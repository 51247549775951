import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import 'src/common/styles/collapse-text.scss';


const CollapseText = ({ id, title, text }) => {
    const [open, setOpen] = useState(false);
    return (
      <div className="collapse-text">
        <div
          className="collapse-text-button"
          onClick={() => setOpen(!open)}
          aria-controls={`collapse-text-${id}`}
          aria-expanded={open}
        >
          <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} />
          {title}
        </div>
        <Collapse in={open}>
          <div id={`collapse-text-${id}`} className="collapse-text-text">
            {text}
          </div>
        </Collapse>
      </div>
    );
};

CollapseText.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};

export default CollapseText;
