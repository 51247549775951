import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep } from 'lodash';

import 'src/guarantee_calculator/styles/guarantee-calculator.scss';
import 'src/guarantee_calculator/styles/week.scss';

import AdminPage from 'src/common/AdminPage';
import { nextID } from 'src/guarantee_calculator/calculatorHelpers';
import Week, { WeekModel } from 'src/guarantee_calculator/Week';
import Results from 'src/guarantee_calculator/Results';


const GuaranteeCalculator = () => {
  const [weeks, setWeeks] = useState([new WeekModel(1, 1, 0, 'DAY', [])]);
  const [reset, setReset] = useState(false);

  const onCopyWeek = week => {
    setWeeks(prevWeeks => {
      let newWeek = cloneDeep(week);
      newWeek.id = nextID(weeks);
      newWeek.number = prevWeeks.length+1;
      return [...prevWeeks, newWeek]
    });
  };

  const onDeleteWeek = useCallback(weekID => {
    if (weeks.length > 1) {
      setWeeks(prevWeeks => {
        const newWeeks = prevWeeks.filter(week => week.id !== weekID);
        return newWeeks.map((week, index) => {
          week.number = index+1;
          return week;
        });
      });
    }
    else {
      setWeeks([new WeekModel(1, 1, 0, 'DAY', [])]);
      setReset(true);
    }
  }, [weeks]);

  const onChangeWeek = useCallback((id, weekData) => {
    setWeeks(prevData => {
      const newData = [...prevData];
      const index = newData.findIndex(week => week.id === id);
      newData[index] = weekData;
      return newData;
    });
    setReset(false);
  }, []);

  const onAddWeek = _ => {
    setWeeks(prevWeeks => [...prevWeeks, new WeekModel(nextID(weeks), weeks.length+1, 0, 'DAY', [])]);
  };

  return (
    <AdminPage hideFooter={true} title="GUARANTEE CALCULATOR">
      <div className="guarantee-calculator">
        <div className="calculator-inputs">
          {weeks.map(week =>
            // this key forces a re-render when a single week exists and is deleted
            <Week
              key={`week-${week.id}-${reset}`}
              week={week}
              onCopy={onCopyWeek}
              onDelete={onDeleteWeek}
              onChange={onChangeWeek} />
          )}

          <div className="week add-button" onClick={onAddWeek}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </div>
        </div>
        <div className="calculator-results">
          <Results weeks={weeks} />
        </div>
      </div>
    </AdminPage>
  );
};

export default GuaranteeCalculator;
