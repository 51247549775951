import { useEffect, useRef, useState } from 'react';

export const useEditMode = isEditMode => {
  const [mode, setMode] = useState(isEditMode ? 'EDIT' : 'READ');

  const ref = useRef();
  useEffect(_ => {
    if(mode === 'EDIT' && ref && ref.current) {
      ref.current.focus();
    }
  }, [mode]);

  return [mode, setMode, ref];
}
