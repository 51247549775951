import React, { useState } from 'react';
import validator from 'validator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import DeleteButton from 'src/common/DeleteButton';
import Setting from 'src/fundraiser/setting/Setting';

import 'src/fundraiser/setting/styles/emailfield-setting.scss';


const EmailfieldSetting = ({ id, title, description, emailAddresses, hasUnsavedValue, onAdd, onRemove }) => {
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailSave = () => {
    if(validator.isEmail(newEmail)) {
      onAdd(newEmail);
      setNewEmail('');
      setError('');
    }
    else {
      setError('Invalid email address');
    }
  };

  return (
    <div className="email-field-setting">
      <Setting
        id={id}
        title={title}
        description={description}
        hasUnsavedValue={hasUnsavedValue}
      >
        <div className="ml-auto">
          <div className="input-container">
            <input className="form-control" type="email" placeholder="Enter an email address" aria-label="cc emails" value={newEmail} onChange={e => setNewEmail(e.target.value)} />
          </div>
          <div className="input-group-append" onClick={handleEmailSave}>
            <FontAwesomeIcon className="add-icon" icon={faPlus} title="Save" />
          </div>
        </div>
        {error && <div className="email-error">{error}</div>}
        <div className="email-field-list col-12 text-left pl-4 mt-2">
          {emailAddresses.sort().map(email =>
            <div key={`email-field-${email}`} className="email-address">
              <span className="overflow-hidden text-truncate align-self-center">{email}</span>
              <DeleteButton clickAction={() => onRemove(email)} />
            </div>
          )}
        </div>
      </Setting>
    </div>
  );
}

export default EmailfieldSetting;
