import {
  INCIDENT_TYPES,
  INCIDENT_TYPE_SETTINGS,
  CSV_FILE_TYPES,
  ZIP_FILE_TYPES,
  ACH_INFO_TYPE,
  SSN_INFO_TYPE
} from 'src/guard/constants';

export const incidentSettingEnabled = (settings, incidentType) => {
  if(incidentType === INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE) {
    return settings[INCIDENT_TYPE_SETTINGS.OUTBOUND_ATTACHMENT_FILESIZE[0]] > 0;
  }
  for(let incidentSetting of INCIDENT_TYPE_SETTINGS[INCIDENT_TYPES[incidentType]]) {
    if(settings[incidentSetting] === 1) {
      return true;
    }
  }
  return false;
};

export const incidentInfoFilter = (settings, incident) => {
  const info = JSON.parse(incident.incidentInfo);
  const type = incident.incidentType.name;
  return (
    (
      type === INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE
      && settings['attachment_size_threshold'] > 0
      && info['size'] > settings['attachment_size_threshold']
    )
    || (
      type === INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE
      && (
        (
          settings['attachment_type_check_spreadsheet']
          && CSV_FILE_TYPES.includes(info['type'])
        )
        || (
          settings['attachment_type_check_archive']
          && ZIP_FILE_TYPES.includes(info['type'])
        )
      )
    )
    || (
      type === INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE
      && (
        (
          settings['sent_sensitive_info_ach']
          && info['incident_info'] === ACH_INFO_TYPE
        )
        || (
          settings['sent_sensitive_info_ssn']
          && info['incident_info'] === SSN_INFO_TYPE
        )
      )
    )
    || (
      type === INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION
      || type === INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH
      || type === INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK
    )
  );
}
