import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import QueryWrapper from 'src/common/QueryWrapper';

import { loader } from 'graphql.macro';
const GET_INCIDENT_TYPES = loader('../graphql/guard/get_incident_types.gql');


const withIncidentTypes = WrappedComponent => {
  const SettingComponent = props => {
    return (
      <QueryWrapper queryResult={useQuery(GET_INCIDENT_TYPES)}>
        {data => <WrappedComponent incidentTypes={data.incidentTypes} {...props} />}
      </QueryWrapper>
    );
  }

  return SettingComponent;
}

export default withIncidentTypes;
