import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { replace } from 'lodash';

import 'src/dashboard/styles/portfolio-pages.scss';

import LinkWrapper from 'src/common/LinkWrapper';
import QueryWrapper from 'src/common/QueryWrapper';
import withFundraiserSetting from 'src/fundraiser/setting/withFundraiserSetting';

const GET_USER_PAGES = loader('src/graphql/user/get_user_pages.gql');


const Pages = ({ selectedPortfolioID }) => (
  <QueryWrapper queryResult={useQuery(GET_USER_PAGES, {variables: {portfolioId: selectedPortfolioID}, })}>
    {(data) =>
      <div>
        <div className="row" key={selectedPortfolioID}>
          {/* <div className="col-6 col-sm-6 col-md-3 col-lg-2 mx-auto mt-lg-4 mb-lg-4 text-center">
            <LinkWrapper to={`${process.env.REACT_APP_DJANGO_SERVER}donors`} className="page-button">
              <button className="btn">
                <FontAwesomeIcon className="icon" icon={faHome} />
              </button>
              <span className="button-title mt-3 mb-md-4 mb-sm-3 mb-3">FUNDRAISER DASHBOARD</span>
            </LinkWrapper>
          </div> */}
          {data.userPages.map((page, index) => (
            <div className="col-6 col-sm-6 col-md-3 col-lg-2 mx-auto mt-lg-4 mb-lg-4 text-center" key={`page-${page.page_type}-${index}`}>
              <LinkWrapper to={page.url} className="page-button">
                <button className="btn">
                  <img src={page.icon} alt={page.alt} />
                </button>
                <span className="button-title mt-3 mb-md-4 mb-sm-3 mb-3">{replace(page.pageType, /_/g, " ")}</span>
              </LinkWrapper>
            </div>
          ))}
        </div>
      </div>
    }
  </QueryWrapper>
);

const PagesWithSetting = withFundraiserSetting(Pages);

const PortfolioPages = () => (
  <div className="portfolio-pages">
    <PagesWithSetting
      settingKey="guard_reporting"
    />
  </div>
);

export default PortfolioPages;
