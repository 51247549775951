import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chart, Pie } from 'react-chartjs-2';

import { getDefaultReportColors } from 'src/common/colorHelpers';
import { buildCSVFromPieChart } from 'src/common/csvHelpers';
import { commonChartOptions } from 'src/report_dashboard/reportHelpers';
import { useDownloadReady } from 'src/report_dashboard/useDownloadReady';
import NoReportData from 'src/report_dashboard/NoReportData';


const defaultOnClick = Chart.defaults.pie.legend.onClick;

const PieChartReport = ({ slices, downloadData, onDownloadReady }) => {
  useDownloadReady(downloadData, { slices }, buildCSVFromPieChart, onDownloadReady)
  // An array of booleans. Each represents one slice in the slices array.
  const [displayedSlices, setDisplayedSlices] = useState(slices.map(s => true));

  // Calculate the size of the pie, which excludes hidden slices
  const dataSum = useMemo(() => slices.reduce((s, x, i) => displayedSlices[i] ? s += Number(x.value) : s, 0), [slices, displayedSlices]);

  const longestNumberLength = useMemo(() => slices.reduce((longest, curr, i) => displayedSlices[i] && (curr.value.length > longest) ? curr.value.length : longest, 0), [slices, displayedSlices]);

  const chartRef = useRef();
  const pieChartOptions = useMemo(() => {
    const commonOpts = commonChartOptions(true, chartRef);
    commonOpts.plugins.datalabels = {
      ...commonOpts.plugins.datalabels,
      align: 'end',
      font: { size: longestNumberLength > 6 ? 14 : 18 },
      formatter: (value, context) => {
        return context.dataset.data[context.dataIndex] + '\n' + (100*context.dataset.data[context.dataIndex]/dataSum).toFixed(1) + '%';
      },
      display: 'auto'
    };
    commonOpts.tooltips = {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + ': ' + value + ' (' + (100*value/dataSum).toFixed(1) + '%)';
        }
      }
    };
    commonOpts.legend = {
      ...commonOpts.legend,
      position: 'right',
      labels: { fontSize: 18 },
      onClick: (ev, item) => {
        // Call the default bahavior to hide the slice
        defaultOnClick.call(chartRef.current.chartInstance, ev, item);

        setDisplayedSlices(prevState => {
          let newState = [...prevState];
          newState[item.index] = !prevState[item.index];
          return newState;
        });
      }
    };
    return commonOpts;
  }, [chartRef, dataSum, longestNumberLength]);

  return (
    <div className="pie-chart report-results">
      {slices.length ?
        <>
          <Pie
            ref={r => chartRef.current = r}
            data={{
              datasets: [{
                data: slices.map(slice => slice.value),
                backgroundColor: getDefaultReportColors(slices.length),
                datalabels: {
                  // Hide labels for slices less then 5% because they usually don't fit
                  display: slices.map(slice => slice.value > dataSum*0.05 ? true : false),
                }
              }],
              labels: slices.map(slice => slice.name)
            }}
            options={pieChartOptions} />
          <div className="pie-chart-total">Total: {dataSum}</div>
        </>
        : <NoReportData />
      }
    </div>
  );
};

PieChartReport.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  downloadData: PropTypes.bool,
  onDownloadReady: PropTypes.func
};

export default PieChartReport;
