import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CircleButtons from 'src/common/CircleButtons';
import withModal from 'src/common/withModal';


const ButtonComponent = ({ label, value, isRow, title, bodyContent, showButton, modalError, displayModal, setModalError, resetKey }) => {
  useEffect(() => {
    setModalError(modalError)
  }, [setModalError, modalError]);

  return (
    <CircleButtons
      key={resetKey}
      options={[{ label, value }]}
      multiSelect={false}
      required={false}
      defaultValues={[]}
      isRow={isRow}
      onClickButton={() => displayModal(title, bodyContent, showButton)}
    />
  );
};
const ModalButtonComponent = withModal(ButtonComponent);

const ModalButton = props => {
  // The resetKey forces CircleButtons to re-render so that the button isn't
  // enabled when the modal is closed
  const [reset, setReset] = useState(true);
  const onHideModal = () => {
    if(props.onCloseModal) {
      props.onCloseModal();
    }
    setReset(!reset);
  };
  return (
    <ModalButtonComponent {...props} resetKey={reset} onHideModal={onHideModal} onButtonClick={props.onButtonClick} />
  );
};

ModalButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
  isRow: PropTypes.bool,
  title: PropTypes.string,
  bodyContent: PropTypes.node,
  showButton: PropTypes.bool,
  modalClassName: PropTypes.string, // used by withModal
  modalError: PropTypes.string,
  onCloseModal: PropTypes.func
};

export default ModalButton;
