import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Navbar, Toast } from 'react-bootstrap';

import FeedbackModal from 'src/common/FeedbackModal';

import 'src/common/styles/admin-page.scss';


const AdminPage = ({ title, titleComponent, hideFooter, children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastHeader, setToastHeader] = useState('');
  const [toastBody, setToastBody] = useState('');
  const [toastAutoHide, setToastAutoHide] = useState(true);

  const displayToast = (header, body, success) => {
    setToastHeader(header);
    setToastBody(body);
    setShowToast(true);
    setToastAutoHide(success);
  }

  let pageTitle = null;
  if(title) {
    const splitTitle = title.split(/ (.+)/);
    const firstWord = splitTitle[0];
    const remainingTitle = splitTitle.length > 1 ? splitTitle[1] : '';

    pageTitle = <>
      <h2 className="mb-3 font-weight-bold page-title">
        <span className="admin-title-first">{firstWord}</span>
        &nbsp;
        <span className="admin-title-remaining">{remainingTitle}</span>
      </h2>
    </>;
  }

  let navBrand = null;
  if(titleComponent) {
    navBrand = <Navbar.Brand className="admin-title">
      {titleComponent}
    </Navbar.Brand>;
  } else {
    navBrand = <Navbar.Brand className="admin-title" href="/" title="My Raise Home">
      <h1>MY<span>Raise</span></h1>
    </Navbar.Brand>;
  }

  return (
    <div className="admin-page">
      <Navbar className="mt-2">
        {navBrand}
      </Navbar>
      <div className="admin-content container px-3 px-sm-0 py-lg-4 py-md-4 py-sm-3 py-1">
        {pageTitle}
        {children}
      </div>
      {!hideFooter ?
        <div className="admin-footer">
          Powered by
          <a href={process.env.REACT_APP_DJANGO_SERVER}>
            <img alt="Raise Logo" src="https://gravyty.s3.amazonaws.com/Raise/Raise_.png" />
          </a>
        </div>
        : null
      }
      <FeedbackModal displayToast={displayToast} />
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide={toastAutoHide}>
        {toastHeader ? <Toast.Header><span className="mr-auto">{toastHeader}</span></Toast.Header> : null}
        {toastBody ? <Toast.Body>{toastBody}</Toast.Body> : null}
      </Toast>
    </div>
  );
};

AdminPage.propTypes = {
  title: PropTypes.string,
  titleComponent: PropTypes.element,
  children: PropTypes.element,
  hideFooter: PropTypes.bool,
};

AdminPage.defaultProps = {
  title: ""
};

export default AdminPage;
