import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import Select from 'react-select'

import { loader } from 'graphql.macro';

import "react-datepicker/dist/react-datepicker.css";

import { addKeyValueToObjectState } from 'src/common/stateHelpers';


const GET_CHILD_REPORT_INPUT_OPTIONS = loader('../graphql/report_dashboard/get_child_input_options.gql');

const ReportInput = ({ reportInput, reportInputValuesByID, onInputChange, options, onOptionsChange }) => {
  const [getChildReportInputOptions] = useLazyQuery(GET_CHILD_REPORT_INPUT_OPTIONS, {
    onCompleted: data => data.childReportInputOptions.forEach(inputOptions => onOptionsChange(inputOptions.reportInputId, inputOptions.options))
  });

  const onChange = (id, value, childReportInputs) => {
    if(Array.isArray(value)) {
      getChildReportInputOptions({ variables: { report_input_ids: childReportInputs.map(c => c.id), input_array: value.map(v => v.value) } });
    }
    else {
      getChildReportInputOptions({ variables: { report_input_ids: childReportInputs.map(c => c.id), input_value: value } });
    }
    onInputChange(id, value);
  };

  return (
    <>
      <label className="form-label" htmlFor={`input-${reportInput.id}`}>{reportInput.label}{reportInput.isOptional ? '' : ' *'}</label>
      {reportInput.input.fieldType === 'SELECT' ?
        <Select
          className="select-input"
          id={`input-${reportInput.id}`}
          isClearable={reportInput.isOptional}
          placeholder={reportInput.isOptional ? 'All' : 'Select'}
          isOptionDisabled={opt => !reportInput.isOptional && opt.value === "" }
          options={options}
          onChange={opt => onChange(reportInput.id, opt ? opt.value : '', reportInput.childReportInputs)} />
        : reportInput.input.fieldType === 'DATE' ?
        <DatePicker
          className="form-control"
          id={`input-${reportInput.id}`}
          selected={reportInputValuesByID[reportInput.id]}
          onChange={date => onChange(reportInput.id, date, reportInput.childReportInputs)} />
        : reportInput.input.fieldType === 'MULTISELECT' ?
        <Select
          className="select-input"
          id={`input-${reportInput.id}`}
          isMulti={true}
          isClearable={true}
          placeholder={reportInput.isOptional ? 'All' : 'Select'}
          options={options}
          onChange={selectedData => onChange(reportInput.id, selectedData, reportInput.childReportInputs)} />
        : reportInput.input.fieldType === 'INTEGER' ?
        <input
          pattern="\d*"
          className="form-control"
          id={`input-${reportInput.id}`}
          value={reportInputValuesByID[reportInput.id] || ''}
          onChange={ev => {
            if(parseInt(ev.target.value, 10)) {
              onChange(reportInput.id, parseInt(ev.target.value, 10), reportInput.childReportInputs);
            }
          }}
        />
        : <div>Unsupported Input Type</div>}
    </>
  );
};

// Inputs for data that must be provided prior to running a report
const ReportInputs = ({ reportInputs, reportInputValuesByID, onInputChange }) => {
  const [inputOptions, setInputOptions] = useState(reportInputs.reduce(
    (acc, i) => {
      acc[i.id] = i.input.options;
      return acc;
    },
    {}
  ));

  useEffect(() => {
    setInputOptions(reportInputs.reduce(
      (acc, i) => {
        acc[i.id] = i.input.options;
        return acc;
      },
      {}
    ));
  }, [reportInputs]);

  const onOptionsChange = (reportInputID, newOptions) => {
    setInputOptions(addKeyValueToObjectState(reportInputID, newOptions))
  };

  return (
    <>
      {reportInputs.map(reportInput => (
        <div key={reportInput.id} className="col col-auto">
          <ReportInput
            reportInput={reportInput}
            reportInputValuesByID={reportInputValuesByID}
            onInputChange={onInputChange}
            options={inputOptions[reportInput.id]}
            onOptionsChange={onOptionsChange}
          />
        </div>
      ))}
    </>
  );
};

ReportInputs.propTypes = {
  reportInputs: PropTypes.array.isRequired,
  reportInputValuesByID: PropTypes.object,
  onInputChange: PropTypes.func
};

export default ReportInputs;
