import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import 'src/common/styles/loading.scss'


const Loading = ({ largeSpinner }) => {
  return <div className={`spinner-wrapper${largeSpinner ? ' large-spinner' : ''}`}><FontAwesomeIcon icon={faSyncAlt} spin={true} /></div>;
};

Loading.propTypes = {
  largeSpinner: PropTypes.bool
};

export default Loading;
