import React from 'react';
import classnames from 'classnames';

import Setting from 'src/fundraiser/setting/Setting';

import 'src/fundraiser/setting/styles/toggle-setting.scss';


const ToggleSetting = ({ id, title, description, settingValue, hasUnsavedValue, onToggle }) => {
  return (
    <div className="toggle-setting">
      <Setting id={id} title={title} description={description} hasUnsavedValue={hasUnsavedValue}>
        <div className={classnames('toggle-input align-self-center', settingValue ? 'toggle-enabled' : null)} onClick={onToggle}>
          <span className="toggle" />
        </div>
      </Setting>
    </div>
  );
}

export default ToggleSetting;
