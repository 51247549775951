// Wrap strings in quotes in case they contain a comma. Escape any quotes
// within the string.
const escapeCSVString = data => {
  const str = data !== null ? data.toString() : data;
  if(!str) return "";
  let newStr = str.replace(/"/g, '""');
  return `"${newStr}"`;
};

// Convert data to a number if possible
export const normalizeData = data => {
  if(data === null || data === '') {
    return data;
  } else {
    const dataAsInt = Number(data);
    return isNaN(dataAsInt) ? data : dataAsInt;
  }
}

export const buildCSVFromTable = data => {
  let csv = data.columns.map(col => escapeCSVString(col.name)).join(",") + "\n";
  csv += data.rows.map(row => row.map(cell => escapeCSVString(cell)).join(",")).join("\n");
  return csv;
};

export const buildCSVFromPieChart = data => {
  let csv = data.slices.map(slice => escapeCSVString(slice.name) + "," + escapeCSVString(slice.value)).join("\n");
  return csv;
};

export const buildCSVFromBarGraph = data => {
  let csv = ',' + data.barGroups.map(group => escapeCSVString(group.label)).join(",") + "\n";
  const numRows = data.labels.length;
  for(let i = 0; i < numRows; i++) {
    csv += escapeCSVString(data.labels[i]) + ',';
    csv += data.barGroups.map(group => escapeCSVString(group.values[i])).join(",") + "\n"
  }
  return csv;
}

export const downloadCSV = (csv, title) => {
  const a = document.createElement('a');
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  a.textContent = 'download';
  a.download = `${title}.csv`;
  a.href = URL.createObjectURL(blob);
  a.dispatchEvent(new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false
  }));
};
