// This should be passed to the setState function of the useState hook.
// The state should be an Object.
// Computes a new state given a key and value to add.
export const addKeyValueToObjectState = (key, value) => prevState => {
  let newState = {};
  newState[key] = value;
  return {
    ...prevState,
    ...newState
  };
};

// This should be passed to the setState function of the useState hook.
// The state should be an Object.
// Computes a new state given a key to remove.
export const removeKeyFromObjectState = removeKey => prevState => {
  return Object.keys(prevState)
    .reduce((newState, key) => {
      if(key !== removeKey) {
        newState[key] = prevState[key];
      }
      return newState;
    }, {});
};
