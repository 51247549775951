import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import 'src/guard/styles/email-count-panel.scss';

import StatPanel from 'src/guard/StatPanel';


const EmailCountPanel = ({ emailCounts, isLoading }) => {
  const [ emailDirection, setEmailDirection ] = useState('');

  const emailCount =
    emailDirection === 'SENT'
    ? emailCounts.sent
    : emailDirection === 'RECEIVED'
      ? emailCounts.received
      : emailCounts.sent + emailCounts.received;

  const toggleButton = value => ev => setEmailDirection(prevValue => prevValue === value ? '' : value);

  return (
    <div className="email-count-panel col">
      <StatPanel
        title="TOTAL EMAILS"
        value={emailCount}
        subvalue={
          <div className="buttons">
            <div className={classnames('button', emailDirection === 'SENT' && 'selected')} onClick={toggleButton('SENT')}>SENT</div>
            <div className={classnames('button', emailDirection === 'RECEIVED' && 'selected')} onClick={toggleButton('RECEIVED')}>RECEIVED</div>
          </div>
        }
        isLoading={isLoading}
      />
    </div>
  );
};

EmailCountPanel.propTypes = {
  emailCounts: PropTypes.shape({
    sent: PropTypes.number,
    received: PropTypes.number
  }).isRequired,
  isLoading: PropTypes.bool
};

export default EmailCountPanel;
