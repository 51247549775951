import React from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';

import Loading from 'src/common/Loading';


const QueryWrapper =({ queryResult, children }) => (
  queryResult.loading
    ? <Loading largeSpinner={true} />
    : queryResult.error
      ? queryResult.error.graphQLErrors && queryResult.error.graphQLErrors.length
        // if there are multiple graphql errors they get concatenated in queryResult.error.message, so show each of those instead
        ? uniq(queryResult.error.graphQLErrors.map(e => e.message)).map((e, i) => <p key={`error-${i}`}>Error: {e}</p>)
        : <p>Error: {queryResult.error.message}</p>
      : children(queryResult.data, queryResult.refetch)
);

QueryWrapper.propTypes = {
  queryResult: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    data: PropTypes.object
  }),
  children: PropTypes.func.isRequired
};

export default QueryWrapper;
