import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import 'src/guard/styles/health-bar.scss';


const HealthBar = ({ value, healthy }) => (
  <div className="health-bar">
    <div className="title">ORG HEALTH</div>
    <div className="progress">
      <div className={classnames('progress-bar', healthy && 'healthy' )} role="progressbar" style={{ width: `${value}%` }} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100">
        {value >= 50 ? <div className="progress-bar-text-left">{100 - value}% At Risk</div> : null}
      </div>
      {value < 50 ? <div className="progress-bar-text-right">{100 - value}% At Risk</div> : null}
    </div>
  </div>
);

HealthBar.propTypes = {
  value: PropTypes.number,
  healthy: PropTypes.bool
};

export default HealthBar;
