import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import RangeSlider from 'react-bootstrap-range-slider';

import 'src/guard/styles/settings-controls.scss';

import { INCIDENT_TYPES, INCIDENT_TYPE_COLORS, INCIDENT_TYPE_ICONS, INCIDENT_TYPE_TIPS, MAX_FILE_SIZE } from 'src/guard/constants';
import CheckboxSetting from 'src/guard/CheckboxSetting';
import Setting from 'src/guard/Setting';
import SwitchSetting from 'src/guard/SwitchSetting';

import { loader } from 'graphql.macro';
const SET_SETTING = loader('../graphql/fundraiser/set_setting.gql');
const GET_GUARD_SETTINGS = loader('../graphql/guard/get_guard_settings.gql');


const SettingsControls = ({ settings, refetchQuery }) => {
  const [fileSize, setFileSize] = useState(settings['attachment_size_threshold']/1024);
  const [setSetting] = useMutation(SET_SETTING, {
    refetchQueries: [{ query: GET_GUARD_SETTINGS }],
    onCompleted: _ => refetchQuery(),
    awaitRefetchQueries: true
  });
  const updateSetting = (settingKey, checked) => setSetting({ variables: { portfolioId: '', keyName: settingKey, value: checked ? 1 : 0 } });

  return (
    <div className="settings-controls">
      <Setting
        dataTip={INCIDENT_TYPE_TIPS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]}
        dataTipId={`${INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE}-tip-settings`}
        icon={INCIDENT_TYPE_ICONS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]}
        color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]}
        title="Prohibited File Types"
        hideToggle={true}
        contents={
          <div className="col-12 setting-checkboxes mt-2 mx-2 justify-content-around">
            <CheckboxSetting
              label="CSV/XLS"
              color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]}
              defaultValue={settings['attachment_type_check_spreadsheet'] === 1 ? true : false}
              onChange={checked => updateSetting('attachment_type_check_spreadsheet', checked)}
            />
            <CheckboxSetting
              label="ZIP"
              color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILETYPE]}
              defaultValue={settings['attachment_type_check_archive'] === 1 ? true : false}
              onChange={checked => updateSetting('attachment_type_check_archive', checked)}
            />
          </div>
        }
      />
      <Setting
        dataTip={INCIDENT_TYPE_TIPS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]}
        dataTipId={`${INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE}-tip-settings`}
        icon={INCIDENT_TYPE_ICONS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]}
        color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_ATTACHMENT_FILESIZE]}
        title="File Size Threshold"
        hideToggle={true}
        contents={
          <div className="col-12 mt-2 px-4">
            <RangeSlider
              value={fileSize}
              max={MAX_FILE_SIZE}
              onChange={ev => setFileSize(ev.target.value)}
              onAfterChange={ev => setSetting({ variables: { portfolioId: '', keyName: 'attachment_size_threshold', value: ev.target.value*1024 } })}
              tooltipPlacement='top'
              tooltipLabel={val => val === '0' ? 'OFF' : val + ' MB'}
            />
          </div>
        }
      />
      <Setting
        dataTip={INCIDENT_TYPE_TIPS[INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]}
        dataTipId={`${INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE}-tip-settings`}
        icon={INCIDENT_TYPE_ICONS[INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]}
        color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]}
        title="Sensitive Data"
        hideToggle={true}
        contents={
          <div className="col-12 setting-checkboxes mt-2 mx-2 justify-content-around">
            <CheckboxSetting
              label="ACH"
              color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]}
              defaultValue={settings['sent_sensitive_info_ach'] === 1 ? true : false}
              onChange={checked => updateSetting('sent_sensitive_info_ach', checked)}
            />
            <CheckboxSetting
              label="SSN"
              color={INCIDENT_TYPE_COLORS[INCIDENT_TYPES.OUTBOUND_BODY_SENSITIVE]}
              defaultValue={settings['sent_sensitive_info_ssn'] === 1 ? true : false}
              onChange={checked => updateSetting('sent_sensitive_info_ssn', checked)}
            />
          </div>
        }
      />
      <SwitchSetting
        dataTip={INCIDENT_TYPE_TIPS[INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION]}
        dataTipId={`${INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION}-tip-settings`}
        icon={INCIDENT_TYPE_ICONS[INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION]}
        title="Anonymous Donor"
        settings={settings}
        type={INCIDENT_TYPES.OUTBOUND_BODY_ANON_DONOR_MENTION}
        updateSetting={updateSetting}
      />
      <SwitchSetting
        dataTip={INCIDENT_TYPE_TIPS[INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH]}
        dataTipId={`${INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH}-tip-settings`}
        icon={INCIDENT_TYPE_ICONS[INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH]}
        title="Spear Phishing"
        settings={settings}
        type={INCIDENT_TYPES.INBOUND_HEADER_FROM_REPLYTO_MISMATCH}
        updateSetting={updateSetting} />
      <SwitchSetting
        dataTip={INCIDENT_TYPE_TIPS[INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK]}
        dataTipId={`${INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK}-tip-settings`}
        icon={INCIDENT_TYPE_ICONS[INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK]}
        title="Suspicious Links"
        settings={settings}
        type={INCIDENT_TYPES.INBOUND_BODY_DECEPTIVE_LINK}
        updateSetting={updateSetting} />
    </div>
  );
};

export default SettingsControls;
