import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import 'src/guard/styles/stat-panel.scss';

import withModal from 'src/common/withModal';


const StatPanel = props => {
  const {
    title, value, valueColor, subvalue,
    subvalueClassname, dataTip, dataTipId, isLoading,
    modalIcon, modalTitle, modalContent, displayModal
  } = props;

  const tooltip = (
    <Tooltip id={dataTipId} className="stat-tip">
      {dataTip}
    </Tooltip>
  );

  return (
    <div className="stat-panel m-auto">
      {modalIcon && modalContent
      ? <div className="stat-panel-modal-button" style={{ '&:hover': { color: valueColor } }} onClick={() => displayModal(modalTitle, modalContent)}>
          <FontAwesomeIcon icon={modalIcon} size="1x" />
        </div>
      : null
      }

      {typeof dataTip === 'string'
      ? <OverlayTrigger trigger="click" placement="top" rootClose overlay={tooltip}>
          <div className="incident-tip">
              <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
          </div>
        </OverlayTrigger>
      : null
      }

      <div className="stat-panel-title">{title}</div>
      {isLoading
        ? <div className="stat-panel-loading">-</div>
        : <>
          <div className="stat-panel-value" style={{color: valueColor}}>{value}</div>
          {
            typeof subvalue === 'string'
            ? <div className={classnames('stat-panel-subvalue-text', subvalueClassname)}>{subvalue}</div>
            : <div className="stat-panel-subvalue">{subvalue}</div>
          }
        </>
      }
    </div>
  );
};

StatPanel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  valueColor: PropTypes.string,
  subvalue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subvalueClassname: PropTypes.string,
  dataTip: PropTypes.string,
  dataTipId: PropTypes.string,
  isLoading: PropTypes.bool,
  modalIcon: PropTypes.object,
  modalContent: PropTypes.element,
  displayModal: PropTypes.func
};

export default withModal(StatPanel);
