import React , { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment-timezone';

import { sortData } from 'src/report_dashboard/tableHelpers';


const IncidentTable = ({ emails }) => {
  const [sortColumnIndex, setSortColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState('');

  const emailData = emails.map(email => [
    moment(email.messageTimestamp).tz('America/New_York').format('YYYY-MM-DD HH:mm z'),
    JSON.parse(email.senders).map(s => s.name + ' <' + s.email + '>').join('; '),
    JSON.parse(email.to).map(t => t.name + ' <' + t.email + '>').join('; '),
    email.subject
  ]);

  const onClickColumn = (colIndex) => () => {
    if(colIndex === sortColumnIndex) {
      if(sortDirection === 'ASC') {
        setSortDirection('DESC');
      } else if(sortDirection === 'DESC') {
        setSortDirection('');
      } else {
        setSortDirection('ASC');
      }
    } else {
      setSortDirection('ASC');
      setSortColumnIndex(colIndex);
    }
  };

  return (
    <div className="guard-email-table data-table">
      <table>
        <tbody>
          <tr>
            {['Date', 'From', 'To', 'Subject'].map((col, colIndex) => (
              <th align="left" key={col} onClick={onClickColumn(colIndex)}>
                <div className="column-title-row row no-gutters">
                  <div className="column-title col-10">
                    {col}
                    {
                      sortDirection && sortColumnIndex === colIndex ?
                        sortDirection === 'ASC' ?
                          <FontAwesomeIcon icon={faSortUp} />
                          : sortDirection === 'DESC' ? <FontAwesomeIcon icon={faSortDown} />
                          : <FontAwesomeIcon icon={faSort} />
                        : <FontAwesomeIcon icon={faSort} />
                    }
                  </div>
                </div>
              </th>
            ))}
          </tr>
          {sortData(emailData, sortColumnIndex, sortDirection).map((row, rowNum) => (
            <tr key={`incident-email-row-${rowNum}`}>
              {row.map((cell, colNum) => <td key={`incident-email-cell-${rowNum}-${colNum}`}>{cell}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

IncidentTable.propTypes = {
  emails: PropTypes.array
};

export default IncidentTable;
