import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import QueryWrapper from 'src/common/QueryWrapper';

import { loader } from 'graphql.macro';
const GET_ORG_USERS = loader('../graphql/user/get_org_users.gql');


// only fetch users with setting enabled
const withOrgUsers = (WrappedComponent, setting) => {
  const Component = props => {
    let variables = {};
    if(setting) {
      variables['setting'] = setting;
    }
    return (
      <QueryWrapper queryResult={useQuery(GET_ORG_USERS, { variables })}>
        {data => <WrappedComponent users={data.orgUsers} {...props} />}
      </QueryWrapper>
    );
  }

  return Component;
}

export default withOrgUsers;
