import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


// This wraps a component and passes it function props for showing and hiding a modal.
const withModal = WrappedComponent => {
  const ModalPage = props => {
    const [ modalTitle, setModalTitle ] = useState('');
    const [ modalBody, setModalBody ] = useState(null);
    const [ modalButton, setModalButton ] = useState(false);
    const [ modalActive, setModalActive ] = useState(false);
    const [ modalError, setModalError ] = useState('');

    const displayModal = (title, body, showButton) => {
      setModalTitle(title);
      setModalBody(body);
      setModalButton(showButton);
      setModalActive(true);
      setModalError('');
    };

    const hideModal = () => {
      setModalActive(false);
      if(props.onHideModal) {
        props.onHideModal();
      }
    };

    const buttonClick = () => {
      if(
        (props.onButtonClick && props.onButtonClick())
        || !props.onButtonClick
      ) {
          setModalActive(false);
      }
    };

    // if modals are nested, hiding a child modal shouldnt trigger a parent modal to activate
    // to disable this, stop the event propogation at the modal level
    return (
      <>
        <Modal size="lg" show={modalActive} onHide={hideModal} dialogClassName={props.modalClassName} centered={true}>
          <Modal.Header closeButton={true}>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalBody}
            {modalError ? <Alert className="my-2" variant="danger">{modalError}</Alert> : ''}
          </Modal.Body>
          {modalButton ?
          <Modal.Footer>
            <Button onClick={buttonClick}>Submit</Button>
          </Modal.Footer>
          : null
          }
        </Modal>
        <WrappedComponent displayModal={displayModal} hideModal={hideModal} setModalError={setModalError} {...props} />
      </>
    );
  }

  return ModalPage;
};

export default withModal;
