import { sortBy } from 'lodash';
import moment from 'moment';

import {
  birthdayImage,
  birthdaySentImage,
  firstDraftImage,
  firstDraftSentImage,
  giftFirstLargeImage,
  giftFirstSmallImage,
  giftLargeImage,
  giftLargeIncreaseImage,
  giftSmallImage,
  giftSmallIncreaseImage,
  phoneImage,
  letterImage,
  stewardshipImage,
  stewardshipSentImage,
  textImage
} from 'src/report_dashboard/donor_journey/actionImages';


// donorEvent is a donor event in the form returned by the Donor Journey report.
// Returns the amount of a gift in cents, as an integer
const getGiftAmount = donorEvent => parseInt(donorEvent[5].replace(/\$|,|\./g, ''), 10);

// Takes all events for a donor and a specific gift.
// Returns the gift the occurred before the given gift, or null is no previous gift exists.
const getPreviousGift = (donorEvents, gift) => {
  const sortedGifts = [...donorEvents].filter(
    e => (e[1] ==='gift' || e[1] === 'first_gift') && e[2] < gift[2]
  ).sort();
  return sortedGifts.length > 0 ? sortedGifts[sortedGifts.length - 1] : null;
};

export const getEventImage = (donorEvents, eventIndex) => {
  const donorEvent = donorEvents[eventIndex];
  if(donorEvent[1] === 'gift') {
    const giftAmount = getGiftAmount(donorEvent);
    const previousGift = getPreviousGift(donorEvents, donorEvents[eventIndex]);
    const increasedGift = previousGift && giftAmount > getGiftAmount(previousGift);
    if(giftAmount >= 1000000) {
      return increasedGift ? giftLargeIncreaseImage: giftLargeImage;
    }
    return increasedGift ? giftSmallIncreaseImage : giftSmallImage;
  }
  else if(donorEvent[1] === 'first_gift') {
    if(getGiftAmount(donorEvent) >= 1000000) {
      return giftFirstLargeImage;
    }
    return giftFirstSmallImage;
  }
  else if(donorEvent[1] === 'prompt') {
    if(donorEvent[4] === 'Stewardship') {
      return stewardshipImage;
    }
    else if(donorEvent[4] === 'Birthday') {
      return birthdayImage;
    }
    return firstDraftImage;
  }
  else if(donorEvent[1] === 'send_first_draft') {
    if(donorEvent[4] === 'Stewardship') {
      return stewardshipSentImage;
    }
    else if(donorEvent[4] === 'Birthday') {
      return birthdaySentImage;
    }
    return firstDraftSentImage;
  }
  else if(donorEvent[1] === 'clicked_phone') {
    return phoneImage;
  }
  else if(donorEvent[1] === 'clicked_letter') {
    return letterImage;
  }
  else if(donorEvent[1] === 'clicked_text') {
    return textImage;
  }
  else {
    return 'circle';
  }
};

export const getEventName = donorEvent => {
  if(donorEvent[1] === 'gift') {
    return donorEvent[4];
  }
  else if(donorEvent[1] === 'first_gift') {
    return 'First ' + donorEvent[4];
  }
  else if(donorEvent[1] === 'prompt') {
    return donorEvent[4] + ' Prompt';
  }
  else if(donorEvent[1] === 'send_first_draft') {
    return donorEvent[4] + ' Sent';
  }
  else if(donorEvent[1] === 'clicked_phone') {
    return 'Phone Call';
  }
  else if(donorEvent[1] === 'clicked_letter') {
    return 'Email/Letter Sent';
  }
  else if(donorEvent[1] === 'clicked_text') {
    return 'Text Sent';
  }
  else {
    return donorEvent[1];
  }
};

export const getEventSubtitle = donorEvent => {
  if(donorEvent[1] === 'gift' || donorEvent[1] === 'first_gift') {
    return donorEvent[5];
  }
  return '';
};

export const getEventTooltip = donorEvent => {
  if(donorEvent[1] === 'gift') {
    return donorEvent[4] + ': ' + donorEvent[5];
  }
  else if(donorEvent[1] === 'first_gift') {
    return 'First ' + donorEvent[4] + ': ' + donorEvent[5];
  }
  else if(donorEvent[1] === 'prompt') {
    return donorEvent[4] + ' Prompt';
  }
  else if(donorEvent[1] === 'send_first_draft') {
    return donorEvent[4] + ' Sent';
  }
  else if(donorEvent[1] === 'clicked_phone') {
    return 'Phone Call';
  }
  else if(donorEvent[1] === 'clicked_letter') {
    return 'Email/Letter Sent';
  }
  else if(donorEvent[1] === 'clicked_text') {
    return 'Text Sent';
  }
  else {
    return donorEvent[1];
  }
};

// donorEvent is a donor event in the form returned by the Donor Journey report.
// Returns the time of the event in a readable format
export const getEventTime = donorEvent => donorEvent[3] ? moment(donorEvent[3]).tz('America/New_York').format('MMM D, YYYY h:mm:ss A z') : moment(donorEvent[2]).format('MMM D, YYYY');

// donorEvent is a donor event in the form returned by the Donor Journey report.
// Returns the time of the event in a format that can be sorted
export const getSortableEventTime = donorEvent => donorEvent[3] ? moment(donorEvent[3]).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss') : moment(donorEvent[2]).format('YYYY-MM-DD');

// donorEvents is a list of donor events in the form returned by the Donor Journey report.
// Sorts the events by the datetime value, if it exists, otherwise the date value is used
// Events with only a date (currently just Gifts) are placed at the end of the day instead of the beginning.
// This is done because we don't know what time the gift was given and we want to assume it was in response to any FD from that same day.
export const sortEventsByDate = donorEvents => sortBy(donorEvents, e => e[3] || moment(e[2]).add(1, 'd').subtract(1, 's').format('YYYY-MM-DD HH:mm:ss'));
