import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import QueryWrapper from 'src/common/QueryWrapper';
import withSelectedPortfolio from 'src/fundraiser/withSelectedPortfolio';

import { loader } from 'graphql.macro';
const GET_FUNDRAISER_SETTING = loader('src/graphql/fundraiser/get_fundraiser_setting.gql');


// Fetches the value of a fundraiser setting for a portfolio
const withFundraiserSetting = WrappedComponent => {
  const SettingComponent = props => {
    return (
      <QueryWrapper
        queryResult={
          useQuery(
            GET_FUNDRAISER_SETTING,
            { variables: { portfolioId: props.selectedPortfolioID, setting: props.settingKey } }
          )
        }
      >
        {data =>
          <WrappedComponent settingValue={data.setting.value} {...props} />
        }
      </QueryWrapper>
    );
  }

  return withSelectedPortfolio(SettingComponent);
}

export default withFundraiserSetting;
